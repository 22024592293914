import * as Yup from 'yup';
import i18n from '../../i18n';

Yup.addMethod(Yup.string, 'password', function() {
  return this.test(
    'password',
    i18n.t('validator.reset_password.password_rule'),
    function(value) {
      if (value) {
        //Password should contain 8 - 30 characters
        //with lowerCase, upperCase letters and numbers
        const pattern = '^(?=.{8,30}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])';

        return !!value.match(new RegExp(pattern));
      }

      return true;
    }
  );
});
