import React, { Component } from 'react';
import { Container, Image, Menu, Responsive, Sidebar, Icon } from 'semantic-ui-react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { logout } from '../../redux/ducks/login';
import { connect } from 'react-redux';
import icons from '../../styles/svgs';
import Avatar from '../../components/Avatar';
import SubMenu from './SubMenu';
import { getCurrentRoute } from '../../helpers/getCurrentRoute';

import styles from './index.module.scss';

class MobileContainer extends Component {
  state = {};

  menuItems = [
    {
      url: '',
      name: 'home',
      icon: icons.Dashboard,
    },
    {
      url: 'transfer-history',
      name: 'transfer-history',
      icon: icons.CurrencyMenuItem,
      businessRequired: true,
    },
    {
      url: 'payment-history',
      name: 'payment-history',
      icon: icons.CurrencyMenuItem,
      businessRequired: true,
    },
    {
      url: 'notifications',
      name: 'notifications',
      icon: icons.Notification,
      businessRequired: true,
    },
    {
      name: 'settings',
      icon: icons.Settings,
      menuItems: [
        {
          url: 'change-password',
          name: 'change_password',
          icon: icons.Lock,
        },
        {
          url: 'e-invoicing',
          name: 'e_invoicing',
          icon: icons.EInvoicing,
          businessRequired: true,
        },
        {
          url: 'business-account',
          name: 'business_account',
          icon: icons.BusinessAccountSetting,
          businessRequired: true,
        },
      ],
    },
  ];

  getActiveMenuItem = () => {
    const {
      location: { pathname },
    } = this.props;

    const url = pathname.split('/')[1];

    const additionalMenuItems = [];

    this.menuItems.forEach(el => {
      el.menuItems && additionalMenuItems.push(...el.menuItems);
    });

    return [...this.menuItems, ...additionalMenuItems].find(elem => elem.url === url);
  };

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) {
      this.setState({ sidebarOpened: false });
    }
  };

  isActive = item => {
    const activeMenuItem = this.getActiveMenuItem();

    return activeMenuItem ? activeMenuItem.url === item.url : item.name === 'home';
  };

  isSubMenuActive = subMenuItems => {
    let isActive = false;

    subMenuItems.forEach(elem => {
      if (this.isActive(elem)) {
        isActive = true;
      }
    });

    return isActive;
  };

  getTranslationForPathName = path => {
    const pathName = path.split('/')[1];
    const translationKey = `menu.${pathName}`;

    return this.props.t(translationKey);
  };

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened });

  logout = () => {
    this.props.logout();
  };

  render() {
    const {
      t,
      children,
      profile,
      business,
      location: { pathname },
      location,
      routes,
    } = this.props;
    const { sidebarOpened } = this.state;
    const header = getCurrentRoute(routes, pathname);

    return (
      <Responsive maxWidth={Responsive.onlyWidescreen.maxWidth} id={styles.hamburger_menu}>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="push"
            vertical
            visible={sidebarOpened}
            className={styles.sidebar_menu}
          >
            <Menu.Item as="a" header className={styles.profile_item}>
              <div className={styles.profile_item_inner}>
                <Avatar src={profile.image} rounded className={styles.avatar_image} />
                <div className={styles.profile_content}>
                  <p>{business.name}</p>
                  <p>{`${profile.firstName} ${profile.lastName}`}</p>
                  <p className={styles.contract_number}>
                    {t(`menu.contract-number`)}
                    <span>{business.contractNumber}</span>
                  </p>
                </div>
              </div>
            </Menu.Item>
            {this.menuItems.map((item, index) => {
              if (item.menuItems) {
                return (
                  <Menu.Item
                    key={index}
                    active={this.isSubMenuActive(item.menuItems)}
                    className={classNames(styles.menu_item, styles.parent_item)}
                  >
                    <SubMenu
                      key={index}
                      icon={item.icon}
                      title={item.name}
                      menuItems={item.menuItems}
                      toggle={this.handleToggle}
                      isActive={this.isActive}
                      profile={profile}
                    />
                  </Menu.Item>
                );
              }
              if (item.businessRequired && !profile.hasBusiness) return null;
              return (
                <Menu.Item
                  key={index}
                  as="a"
                  active={this.isActive(item)}
                  onClick={() => {
                    this.props.history.push(`/${item.url}`);
                    this.handleToggle();
                  }}
                  className={styles.menu_item}
                >
                  <span>
                    <Image src={item.icon} className={styles.menu_item_icon} />
                    {t(`menu.${item.name}`)}
                  </span>
                </Menu.Item>
              );
            })}
            <Menu.Item as="a" className={styles.menu_item} onClick={this.logout}>
              <span>
                <Image src={icons.SignOut} className={styles.menu_item_icon} />
                {t('menu.logout')}
              </span>
            </Menu.Item>
            {/*<Menu.Item as="a" className = {styles.menu_item}><span>{t('menu.vendors')}</span></Menu.Item>*/}
            {/*<Menu.Item as="a" className = {styles.menu_item}><span>{t('menu.suppliers')}</span></Menu.Item>*/}
            {/*<Menu.Item as="a" className = {styles.menu_item}><span>{t('menu.transactions')}</span></Menu.Item>*/}
          </Sidebar>

          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={this.handlePusherClick}
            style={{ minHeight: '100vh' }}
          >
            {header && !header.hideHeader && (
              <Container fluid className={styles.menu_fluid}>
                <Menu secondary size="large" className={styles.menu_header}>
                  {header && header.leftBtn ? (
                    <Menu.Item onClick={() => header.leftBtn(location)} className={styles.icon}>
                      <Icon name="arrow left" />
                    </Menu.Item>
                  ) : (
                    <Menu.Item onClick={this.handleToggle} className={styles.icon}>
                      <Image name="sidebar" src={icons.Menu} />
                    </Menu.Item>
                  )}
                  <Menu.Header className={styles.header_text}>
                    {(header && header.title) || this.getTranslationForPathName(pathname)}
                  </Menu.Header>
                  {header && header.rightBtn ? (
                    <Menu.Item onClick={header.rightBtn} className={styles.icon}>
                      <Icon name="refresh" />
                    </Menu.Item>
                  ) : (
                    <Menu.Item className={classNames(styles.icon, styles.hidden)}>
                      <Icon />
                    </Menu.Item>
                  )}
                </Menu>
              </Container>
            )}
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default compose(
  withTranslation('translations'),
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(MobileContainer);
