import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ResponsiveContainer from './ResponsiveContainer';
import _ from 'lodash';
import NotificationContainer from '../../components/NotificationContainer';
import { getUserProfile } from '../../redux/ducks/userProfile';
import { getMyBusiness } from '../../redux/ducks/business';
import { withTranslation } from 'react-i18next';
import { Dimmer } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import GCButton from '../../components/GCButton';
import BusinessUpdatedInfo from '../../components/BusinessUpdatedInfo';
import LoanUpdateInfo from '../../components/LoanUpdateInfo';
import { getCurrentRoute } from '../../helpers/getCurrentRoute';

import styles from './index.module.scss';

class SiteWrapper extends Component {
  state = { isBusinessErrorDismissed: false };

  componentDidMount() {
    const {
      location: { pathname },
      history,
      fetchProfile,
      fetchBusiness,
      routes,
    } = this.props;

    fetchProfile()
      .then(res => {
        if (res.hasBusiness) fetchBusiness(routes, pathname);

        const { businessRequired } = getCurrentRoute(routes, pathname);
        if (businessRequired && !res.hasBusiness) history.push('/home');
      })
      .catch(() => {});
  }

  componentDidUpdate() {
    const {
      profile,
      business,
      history,
      location: { pathname },
    } = this.props;

    if (!_.isEmpty(profile) && !profile.emailVerifiedAt && !pathname.includes('verification')) {
      history.push('/verification');
    } else if (
      !_.isEmpty(business) &&
      !business.eInvoicingUsername &&
      !pathname.includes('e-invoicing')
    ) {
      history.push('/e-invoicing?notify=true');
    }
  }

  handleClick() {
    this.setState({ isBusinessErrorDismissed: true });
  }

  render() {
    const {
      t,
      profile,
      business,
      children,
      routes,
      business: { lateOnPayments, isUpdating },
      isBusinessUpdatedInfoModalOpen,
    } = this.props;

    const businessError = isUpdating ? '4006' : lateOnPayments ? '4007' : null;
    const showDimmer = businessError && !this.state.isBusinessErrorDismissed;
    const showBusinessUpdatedModal = !_.isEmpty(business) && !showDimmer;
    const showNotificationModal = !isBusinessUpdatedInfoModalOpen && showBusinessUpdatedModal;

    return (
      <ResponsiveContainer profile={profile} business={business} routes={routes}>
        {showNotificationModal && <NotificationContainer />}
        {showBusinessUpdatedModal && <BusinessUpdatedInfo />}
        {<LoanUpdateInfo />}

        {showDimmer && (
          <Dimmer inverted active page>
            <strong id={styles.blocked_message}>{t(`validation_errors.${businessError}`)}</strong>
            {businessError === '4007' && (
              <div>
                <br />
                <GCButton onClick={() => this.handleClick()} large>
                  {t(`validation_errors.button`)}
                </GCButton>
              </div>
            )}
          </Dimmer>
        )}
        {children}
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.userProfile.data,
  business: state.business.data,
  isBusinessUpdatedInfoModalOpen: state.businessUpdatedInfo.isModalOpen,
});

const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(getUserProfile()),
  fetchBusiness: () => dispatch(getMyBusiness()),
});

export default compose(
  withRouter,
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SiteWrapper);
