export const TOKEN = 'token';

export const PRECISION = 2;

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_FORMAT_2 = 'dd/MM/yyyy';

export const DATE_HYPHEN_FORMAT = 'YYYY-MM-DD';

export const MIN_TRANSFER_AMOUNT = 1000;

export const TRANSFER_NOTES_MAX_LENGTH = 255;

export const NOTIFICATION_CHECK_INTERVAL = 180000;

export const BUSINESS_INFO_UPDATE_INTERVAL = 900000; // 15 minutes

export const LONG_DASH = '—';

export const INVOICE_NUMBER_LENGTH = 11;

export const MOBILE_MAX_SIZE = 480;

export const TABLET_MAX_SIZE = 768;

export const LARGE_TABLET_MAX_SIZE = 1024;

export const ARMENIAN_PHONE_CODE = '+374';

export const PHONE_NUMBER_REG_EXP = /_|\(|\)|\s/g;
