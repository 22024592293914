import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import HistoryScreen from '../HistoryScreen';
import TransferItem from './TransferItem';
import { getTransferHistory } from '../../../redux/ducks/transferHistory';

import styles from '../HistoryScreen/index.module.scss';

class TransferHistory extends React.PureComponent {
  componentDidMount() {
    this.props.getTransferHistory();
  }

  render() {
    const { transferHistory, t, loading } = this.props;

    if (transferHistory && !transferHistory.length) {
      return <div className={styles.empty}>{t('transfer-history.empty')}</div>;
    }

    const component = item => <TransferItem item={item} />;

    return (
      <HistoryScreen
        link="transfer-history-details"
        loading={loading}
        historyList={transferHistory}
        component={component}
      />
    );
  }
}

const mapStateToProps = state => ({
  transferHistory: state.transferHistory.data,
  loading: state.transferHistory.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getTransferHistory: id => dispatch(getTransferHistory(id)),
  };
};

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TransferHistory);
