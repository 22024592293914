import React from 'react';
import classNames from 'classnames';
import { Image, List } from 'semantic-ui-react';
import { compose } from 'redux';
import VendorImage from '../../../VendorImage';
import { withTranslation } from 'react-i18next';
import Currency from '../../../Currency';
import { DATE_TIME_FORMAT, LONG_DASH } from '../../../../constants';
import {
  STATES_COLOR,
  TRANSFER_TYPES_TEXT,
  TRANSFER_STATES_TEXT,
} from '../../../../constants/transferStates';
import icons from '../../../../styles/svgs';
import { formatLocalDate } from '../../../../helpers/common';

import styles from '../../HistoryScreen/index.module.scss';

class TransferItem extends React.PureComponent {
  render() {
    const { item, t } = this.props;

    return (
      <React.Fragment>
        <List.Header className={styles.list_header}>
          {formatLocalDate(item.date, DATE_TIME_FORMAT)}
        </List.Header>
        <List.Content className={styles.content}>
          <VendorImage src={item.vendor.image} className={styles.image} />
          <List.Description className={styles.item_description}>
            <div className={styles.row}>
              <div className={classNames(styles.info, styles.left_container)}>
                <div className={styles.label}>{t('transfer-history.vendor')}</div>
                <div className={styles.value}>{item.vendor.name}</div>
              </div>
              <div className={styles.info}>
                <div className={styles.label}>{t('transfer-history.transfer_id')}</div>
                <div className={styles.value}>{item.transferNumber}</div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={classNames(styles.info, styles.left_container)}>
                <div className={styles.label}>{t('transfer-history.invoice_number')}</div>
                <div className={styles.value}>{item.invoiceNumber || LONG_DASH}</div>
              </div>
              <div className={styles.info}>
                <div className={styles.label}>{t('transfer-history.amount')}</div>
                <div className={classNames(styles.value, styles.amount)}>
                  <Currency value={item.totalAmount} hasSign />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={classNames(styles.info, styles.left_container)}>
                <div className={styles.label}>{t('transfer-history.type')}</div>
                <div className={styles.value}>{TRANSFER_TYPES_TEXT[item.type]}</div>
              </div>
              <div className={styles.info}>
                <div className={styles.label}>{t('transfer-history.state')}</div>
                <div
                  className={classNames(
                    styles.value,
                    styles.state,
                    styles[STATES_COLOR[item.state]]
                  )}
                >
                  {TRANSFER_STATES_TEXT[item.state]}
                </div>
              </div>
            </div>
          </List.Description>
          <Image src={icons.HistoryArrow} className={styles.arrow} />
        </List.Content>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation('translations'))(TransferItem);
