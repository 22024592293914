import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import HistoryScreen from '../HistoryScreen';
import PaymentItem from './PaymentItem';
import { getPaymentHistory } from '../../../redux/ducks/paymentHistory';

import styles from '../HistoryScreen/index.module.scss';

class PaymentHistory extends React.PureComponent {
  componentDidMount() {
    this.props.getPaymentHistory();
  }

  render() {
    const { paymentHistory, t, loading } = this.props;

    if (paymentHistory && !paymentHistory.length) {
      return <div className={styles.empty}>{t('payment-history.empty')}</div>;
    }

    const component = item => <PaymentItem item={item} />;

    return (
      <HistoryScreen
        link="payment-history-details"
        loading={loading}
        historyList={paymentHistory}
        component={component}
      />
    );
  }
}

const mapStateToProps = state => ({
  paymentHistory: state.paymentHistory.data,
  loading: state.paymentHistory.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getPaymentHistory: id => dispatch(getPaymentHistory(id)),
  };
};

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PaymentHistory);
