import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Image, Divider } from 'semantic-ui-react';

import { logout, resend } from '../../redux/ducks/login';
import icons from '../../styles/svgs';
import CopyRight from '../CopyRight';
import ContactInfo from '../ContactInfo';
import Loader from '../Loader';
import styles from './index.module.scss';

const VerificationScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { data } = useSelector(state => state.userProfile);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleResend = async () => {
    try {
      setLoading(true);
      await resend();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id={styles.verification_screen}>
      <Loader loading={loading} />
      <div className={styles.header}>
        <div onClick={handleLogout}>{t('sign_up.sign_out_button')}</div>
      </div>
      <div className={styles.verification_container}>
        <Image src={icons.Email} />
        <h2 className={styles.title}>{t('sign_up.verify_email')}</h2>
        <p className={styles.text}>{t('sign_up.verify_email_text', { email: data.email })}</p>
        <Divider />
        <Button type="button" className={styles.resend} onClick={handleResend}>
          {t('sign_up.verify_email_resend')}
        </Button>
      </div>
      <ContactInfo />
      <CopyRight />
    </div>
  );
};

export default VerificationScreen;
