import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Input, FormField } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import querystring from 'query-string';

import { reset } from '../../redux/ducks/login';
import GCButton from '../../components/GCButton';
import PasswordInput from '../PasswordInput';
import schema from '../../validation/schemas/resetPasswordSchema';
import icons from '../../styles/svgs';
import CopyRight from '../../components/CopyRight';
import Validation from '../../components/Validation';
import Loader from '../Loader';
import AuthHeader from '../../components/AuthHeader';

import styles from './index.module.scss';

class ResetPassword extends React.PureComponent {
  componentDidMount() {
    const {
      location: { pathname, search },
    } = this.props;
    const token = pathname.split('/')[2];
    const { email } = querystring.parse(search);

    this.setState({ token, email });
  }

  onSubmit = ({ password, passwordConfirmation }) => {
    const { email, token } = this.state;

    const payload = {
      password,
      password_confirmation: passwordConfirmation,
      email,
      token,
    };

    if (email && token) {
      this.props.reset(payload);
    }
  };

  render() {
    const { t, loading } = this.props;

    return (
      <div id={styles.reset_password_screen}>
        <Loader loading={loading} />
        <AuthHeader />
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={schema}
          onSubmit={this.onSubmit}
          render={props => {
            const { values } = props;

            return (
              <Form className="ui form">
                <div className={styles.container}>
                  <img src={icons.Login} alt="global-credit-logo" />
                  <div className={styles.title}>
                    {t('reset_password.title')}
                  </div>
                  <FormField>
                    <label htmlFor="password" className={styles.label}>
                      <span>{t('reset_password.password')}</span>
                    </label>
                    <Validation name="password" showMessage={true}>
                      <PasswordInput
                        value={values.password}
                        name="password"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <FormField>
                    <label
                      htmlFor="passwordConfirmation"
                      className={styles.label}
                    >
                      <span>{t('reset_password.password_confirm')}</span>
                    </label>
                    <Validation name="passwordConfirmation" showMessage={true}>
                      <Input
                        value={values.passwordConfirmation}
                        name="passwordConfirmation"
                        type="password"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <GCButton type="submit" large>
                    {t('reset_password.button')}
                  </GCButton>
                </div>
              </Form>
            );
          }}
        />
        <CopyRight />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.login.loading,
});

const mapDispatchToProps = dispatch => ({
  reset: data => {
    dispatch(reset(data));
  },
});

export default compose(
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResetPassword);
