import * as Yup from 'yup';
import i18n from '../../i18n';

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t('validator.login.password_required')),
  email: Yup.string()
    .email(i18n.t('validator.login.email_invalid'))
    .required(i18n.t('validator.login.email_required')),
});

export default loginSchema;
