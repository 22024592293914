import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import styles from './index.module.scss';

class ServerError extends Component {
  render() {
    const { t, error = {} } = this.props;

    if (!error || !error.code) {
      return null;
    }

    return (
      <div className={styles.server_error}>
        {t(`validation_errors.${error.code}`)}
      </div>
    );
  }
}

export default compose(withTranslation('translations'))(ServerError);
