import * as Yup from 'yup';
import i18n from '../../i18n';
import './validators';
import { PHONE_NUMBER_REG_EXP } from '../../constants';

const signUpSchema = Yup.object().shape({
  firstName: Yup.string().required(i18n.t('validator.sign_up.firstName_required')),
  lastName: Yup.string().required(i18n.t('validator.sign_up.lastName_required')),
  password: Yup.string()
    .required(i18n.t('validator.sign_up.password_required'))
    .password(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], i18n.t('validator.sign_up.password_equal'))
    .required(i18n.t('validator.sign_up.confirmPassword_required')),
  phoneNumber: Yup.string().test({
    name: 'phoneNumber',
    test: function(val = '') {
      const val_length_without_dashes = val.replace(PHONE_NUMBER_REG_EXP, '').length;

      if (val_length_without_dashes === 0) {
        return this.createError({
          message: i18n.t('validator.sign_up.phone_required'),
        });
      }
      if (val_length_without_dashes !== 8) {
        return this.createError({
          message: i18n.t('validator.sign_up.phone_invalid'),
        });
      }

      return true;
    },
  }),
  email: Yup.string()
    .email(i18n.t('validator.sign_up.email_invalid'))
    .required(i18n.t('validator.sign_up.email_required')),
  terms: Yup.boolean().oneOf([true], i18n.t('validator.sign_up.terms')),
});

export default signUpSchema;
