import axios from 'axios';
import { API_URL } from '../../config';

export const MODAL_OPEN = 'globalcredit-smb/business_updated_info/MODAL_OPEN';
export const MODAL_CLOSE = 'globalcredit-smb/business_updated_info/MODAL_CLOSE';

export const open = () => ({
  type: MODAL_OPEN,
});

export const close = () => ({
  type: MODAL_CLOSE,
});

const initialState = {
  isModalOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        isModalOpen: true,
      };
    case MODAL_CLOSE:
      return {
        isModalOpen: false,
      };
    default:
      return state;
  }
}

export const getBusinessUpdatedInfo = () => {
  return (dispatch, getState) => {
    const state = getState();

    axios
      .get(`${API_URL}/business-updated-info`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;
        const currentVersion = state.business.data.version;

        if (data.version !== currentVersion) {
          dispatch(open());
        }
      })
      .catch(error => {
        dispatch(close());
      });
  };
};

export const changeModalState = state => {
  return dispatch => {
    dispatch(state ? open() : close());
  };
};
