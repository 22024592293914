import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image } from 'semantic-ui-react';
import { verifyUser } from '../../redux/ducks/login';
import icons from '../../styles/svgs';
import CopyRight from '../CopyRight';
import ContactInfo from '../ContactInfo';
import Loader from '../Loader';

import styles from './index.module.scss';

const EmailVerifyScreen = ({ history, location }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(null);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (location.search) {
      setLoading(true);
      const urlParams = new URLSearchParams(location.search);
      const email = urlParams.get('email');
      const verifyURL = urlParams.get('verifyURL');

      verifyUser(decodeURIComponent(verifyURL))
        .then(() => {
          setEmailVerified(true);
          setTimeout(() => history.push('/home'), 3000);
        })
        .finally(() => {
          setEmail(email);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  if (loading) return <Loader loading />;
  if (emailVerified === null) return null;

  return (
    <div id={styles.email_verify_screen}>
      <div className={styles.email_verify_container}>
        {emailVerified ? (
          <>
            <Image src={icons.Success2} />
            <h2 className={styles.title}>{t('sign_up.email_verified')}</h2>
            <p className={styles.text}>{t('sign_up.email_verified_text', { email })}</p>
          </>
        ) : (
          <>
            <Image src={icons.Error2} />
            <h2 className={styles.title}>{t('sign_up.verification_failed')}</h2>
            <p className={styles.text}>{t('sign_up.verification_failed_text', { email })}</p>
            <Button
              type="button"
              className={styles.back_to_home_button}
              onClick={() => history.push('/home')}
            >
              {t('sign_up.back_to_home')}
            </Button>
          </>
        )}
      </div>
      <ContactInfo />
      <CopyRight />
    </div>
  );
};

export default EmailVerifyScreen;
