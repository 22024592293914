import * as Yup from 'yup';
import i18n from '../../i18n';
import {
  MIN_TRANSFER_AMOUNT,
  TRANSFER_NOTES_MAX_LENGTH,
  INVOICE_NUMBER_LENGTH,
} from '../../constants';
import { numberToDecimalString } from '../../helpers/numberHelpers';

const newTransferSchema = (
  { availableBalance = 0, invoiceNumber, supplyDate },
  feeAmount,
  checkInvoiceNumber
) => {
  return Yup.object().shape({
    amount: Yup.number()
      .min(
        MIN_TRANSFER_AMOUNT,
        i18n.t('validator.new_transfer.min_amount', {
          min: numberToDecimalString(MIN_TRANSFER_AMOUNT),
        })
      )
      .required(i18n.t('validator.new_transfer.amount_required'))
      .test('max-invoice-amount', i18n.t('validator.new_transfer.invoice_amount_compare'), function(
        value
      ) {
        if (this.parent.invoiceAmount) return value <= this.parent.invoiceAmount;
        return true;
      })
      .test(
        'max-available-balance',
        i18n.t('validator.new_transfer.amount_limit', {
          limit: numberToDecimalString(availableBalance),
        }),
        function(value) {
          return value + feeAmount <= availableBalance;
        }
      ),
    ...(invoiceNumber && {
      invoiceNumber: Yup.string()
        .required(i18n.t('validator.new_transfer.invoice_number'))
        .test(
          'invoice_number_length',
          i18n.t('validator.new_transfer.invoice_number_max'),
          val => val?.length === INVOICE_NUMBER_LENGTH
        )
        .test(
          'valid_invoice_number',
          i18n.t('validator.new_transfer.invalid_invoice_number'),
          (_, context) => {
            return checkInvoiceNumber || context.parent.checkInvoiceNumber;
          }
        ),
    }),
    ...(supplyDate && {
      supplyDate: Yup.date().required(i18n.t('validator.new_transfer.supplyDate')),
    }),
    notes: Yup.string().max(
      TRANSFER_NOTES_MAX_LENGTH,
      i18n.t('validator.new_transfer.notes_max_length', {
        length: TRANSFER_NOTES_MAX_LENGTH,
      })
    ),
  });
};

export default newTransferSchema;
