import { TOKEN } from '../constants';
import { API_URL } from '../config';
import axios from 'axios';
import store from '../redux/store';
import { authenticationCleanup } from '../redux/ducks/login';

export const setToken = data => {
  localStorage.setItem(TOKEN, data.token);
};

export const getToken = () => localStorage.getItem(TOKEN);

export const removeToken = () => {
  localStorage.removeItem(TOKEN);
};

export const getAuthHeader = () => {
  return { Authorization: `Bearer ${getToken()}` };
};

export const isAuthenticated = () => !!getToken();

export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/refresh`)
      .then(response => {
        const {
          data: { data },
        } = response;

        setToken(data);

        return resolve();
      })
      .catch(reason => {
        removeToken();
        store.dispatch(authenticationCleanup());
        reject(reason);
      });
  });
};

let refreshPromise = null;

axios.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      Object.assign(config.headers, getAuthHeader());
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.config &&
      error.response &&
      error.response.status === 401 &&
      getToken()
    ) {
      refreshPromise = refreshPromise || refreshToken();

      return refreshPromise
        .then(() => {
          refreshPromise = null;
          Object.assign(error.config.headers, getAuthHeader());

          return axios.request(error.config);
        })
        .catch(reason => {
          refreshPromise = null;
        });
    }

    return Promise.reject(error);
  }
);
