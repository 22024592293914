import React from 'react';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Image, Segment } from 'semantic-ui-react';

import TransferDetailsModal from './components/TransferDetailsModal';
import { getVendors } from '../../redux/ducks/vendor';
import { getFeeAmount } from '../../redux/ducks/feeAmount';
import { getTransfer, getCurrentInvoice, setInvoice } from '../../redux/ducks/transferDetails';
import { openModal, closeModal } from '../../redux/ducks/modal';
import { vendorSelector, supplierSelector } from '../../redux/selectors';
import icons from '../../styles/svgs';
import Avatar from '../Avatar';
import TransferForm from './components/TransferForm';
import Loader from '../Loader';
import { formatLocalDate } from '../../helpers/common';
import { DATE_FORMAT, DATE_HYPHEN_FORMAT } from '../../constants';
import { TRANSFER_TYPE_KEYS } from '../../constants/transferStates';
import { transferPagesConfig } from './config';

import styles from './index.module.scss';

class NewTransferScreen extends React.PureComponent {
  state = {
    initialValues: this.props.location.state || {},
  };

  componentWillUnmount() {
    this.props.closeModal();
    this.props.resetCurrentInvoice();
  }

  componentDidMount() {
    const {
      vendor,
      getVendors,
      getTransfer,
      getCurrentInvoice,
      location: { search },
      getFeeAmount,
      transfer,
    } = this.props;
    const { mode, t_id, v_id } = queryString.parse(search);

    if (!vendor) {
      getVendors();
    }

    if (transfer?.amount) {
      getFeeAmount(transfer.amount);
    }
    if (mode === TRANSFER_TYPE_KEYS.unfreeze) {
      getTransfer(t_id)
        .then(transfer => {
          const { supplyDate } = transfer;
          const params = {
            vendorId: v_id,
            supplyStartDate: formatLocalDate(supplyDate, DATE_HYPHEN_FORMAT),
            supplyEndDate: formatLocalDate(supplyDate, DATE_HYPHEN_FORMAT),
          };

          getCurrentInvoice(params, true);
        })
        .catch(error => {});
    }
  }

  onOpenConfirmModal = values => {
    const {
      vendor,
      supplier,
      profile,
      openModal,
      t,
      location: { search },
      transfer = {},
      fee,
    } = this.props;
    const { mode } = queryString.parse(search);
    let { amount, invoiceNumber, supplyDate, notes } = values;
    let transferNumber;

    if (mode === TRANSFER_TYPE_KEYS.unfreeze) {
      transferNumber = transfer.transferNumber;
      supplyDate = transfer.supplyDate;
    }

    if (vendor && supplier) {
      openModal(
        () => {
          return (
            <TransferDetailsModal
              feeAmount={fee.feeAmount}
              amount={amount}
              invoiceNumber={invoiceNumber}
              transferNumber={transferNumber}
              supplyDate={supplyDate}
              notes={notes.trim()}
              vendor={vendor}
              supplier={supplier}
              user={profile}
              type={mode}
              showInvoiceNumber={transferPagesConfig[mode].showInvoiceNumber}
            />
          );
        },
        {
          title: t('menu.transfer-details'),
        }
      );
    }
  };

  onBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { initialValues } = this.state;
    const {
      t,
      businessInfo = {},
      profile = {},
      vendor = {},
      supplier = {},
      location: { search },
      loading,
      transfer = {},
    } = this.props;

    const { mode } = queryString.parse(search);
    const transferPage = transferPagesConfig[mode] || {};
    let availableBalance = businessInfo.availableBalance;
    if (mode === TRANSFER_TYPE_KEYS.unfreeze) {
      availableBalance += transfer.amount || 0;
      availableBalance += transfer.feeAmount || 0;
    }

    const validationData = {
      availableBalance,
      invoiceNumber: transferPage.showEInvoiceField,
      supplyDate: transferPage.supplyDateField,
    };

    return (
      <div id={styles.new_transfer}>
        <Loader loading={loading} />
        <div className={styles.new_transfer_header}>
          <div className={styles.header_column}>{t('new-transfer.buyer')}</div>
          <div className={styles.header_column}>{t('new-transfer.supplier')}</div>
        </div>
        <Segment className={styles.transfer_info_container}>
          <div className={styles.row}>
            <div className={styles.container}>
              <Avatar
                src={businessInfo.image}
                rounded
                defaultIcon="VendorDefault"
                className={styles.avatar_image}
              />
              <div className={styles.business_info}>{businessInfo.name}</div>
            </div>
            <div className={styles.container}>
              <Avatar
                src={vendor.image}
                rounded
                defaultIcon="VendorDefault"
                className={styles.avatar_image}
              />
              <div className={styles.business_info}>{vendor.name}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.container}>
              <div className={styles.info_block}>
                <div className={styles.label_info}>{t('new-transfer.user')}</div>
                <p className={styles.info}>{this.fullName(profile.firstName, profile.lastName)}</p>
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.info_block}>
                <div className={styles.label_info}>{t('new-transfer.sender')}</div>
                <p className={styles.info}>
                  {this.fullName(supplier.firstName, supplier.lastName)}
                </p>
              </div>
            </div>
          </div>
          <Divider vertical>
            <Image src={icons.Arrow} className={styles.arrow_icon} height="29px" />
          </Divider>
        </Segment>
        <div className={styles.new_transfer_form_container}>
          {transferPage.showTransferNumber && (
            <div className={styles.transfer_info}>
              <div>
                <p>{t('transfer_details.transfer_id')}</p>
                <p>{transfer.transferNumber}</p>
              </div>
              <div>
                <p>{t('transfer_details.supplyDate')}</p>
                <p>{formatLocalDate(transfer.supplyDate, DATE_FORMAT)}</p>
              </div>
            </div>
          )}
          <TransferForm
            initialValues={initialValues}
            validationData={validationData}
            transferPage={transferPage}
            disabledButton={businessInfo.lateOnPayments}
            onOpenConfirmModal={this.onOpenConfirmModal}
            onBack={this.onBack}
          />
        </div>
      </div>
    );
  }

  fullName = (firstName = '', lastName = '') => {
    return `${firstName} ${lastName}`.trim();
  };
}

const mapStateToProps = (state, props) => {
  const {
    location: { search },
  } = props;
  const { v_id, s_id } = queryString.parse(search, { parseNumbers: true });
  const vendor = vendorSelector(v_id);
  const supplier = supplierSelector(v_id, s_id);

  return {
    businessInfo: state.business.data,
    profile: state.userProfile.data,
    vendor: vendor(state),
    supplier: supplier(state),
    loading: state.transferDetails.loading || state.transferDetails.invoiceLoading,
    transfer: state.transferDetails.data,
    fee: state.feeAmount,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVendors: () => dispatch(getVendors()),
    openModal: (content, params) => dispatch(openModal(content, params)),
    closeModal: () => dispatch(closeModal()),
    getTransfer: id => dispatch(getTransfer(id)),
    getCurrentInvoice: (params, handleMatching) =>
      dispatch(getCurrentInvoice(params, handleMatching)),
    resetCurrentInvoice: () => dispatch(setInvoice({})),
    getFeeAmount: amount => dispatch(getFeeAmount(amount)),
  };
};

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewTransferScreen);
