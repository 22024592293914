import { BASE_URL } from '../config';
import moment from 'moment';

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

export const getFullPath = file => {
  return `${BASE_URL}/${file}`;
};

export const getImagePath = file => {
  return `${BASE_URL}/images/${file}`;
};

export const formatLocalDate = (date, fmt) => {
  return moment
    .utc(date)
    .local()
    .format(fmt);
};
