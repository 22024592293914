import axios from 'axios';
import { API_URL } from '../../config';

export const PAYMENT_REQUEST = 'globalcredit-smb/payment/PAYMENT_REQUEST';
export const PAYMENT_RESPONSE = 'globalcredit-smb/payment/PAYMENT_RESPONSE';
export const PAYMENT_ERROR = 'globalcredit-smb/payment/PAYMENT_ERROR';

export const fetchPaymentHistoryRequest = payload => ({
  type: PAYMENT_REQUEST,
  payload,
});

export const fetchPaymentHistoryResponse = payload => ({
  type: PAYMENT_RESPONSE,
  payload,
});

export const fetchPaymentHistoryError = payload => ({
  type: PAYMENT_ERROR,
  payload,
});

const initialState = {
  data: null,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_RESPONSE:
      return {
        data: [...action.payload],
        loading: false,
      };
    case PAYMENT_ERROR:
    default:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
  }
}

export const getPaymentHistory = () => {
  return dispatch => {
    dispatch(fetchPaymentHistoryRequest());

    axios
      .get(`${API_URL}/payments`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        return dispatch(fetchPaymentHistoryResponse(data));
      })
      .catch(error => {
        return dispatch(fetchPaymentHistoryError(error));
      });
  };
};
