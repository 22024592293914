import React from 'react';

import styles from './index.module.scss';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class ContactInfo extends React.PureComponent {
  render() {
    return (
      <div
        className={styles.contact_info}
        dangerouslySetInnerHTML={{
          __html: this.props.t('contact_info'),
        }}
      />
    );
  }
}

export default compose(withTranslation('translations'))(ContactInfo);
