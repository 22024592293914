import * as Yup from 'yup';
import i18n from '../../i18n';
import './validators';

const EInvoicingSchema = Yup.object().shape({
  eInvoicingUsername: Yup.string()
    .max(30, i18n.t('validator.e_invoicing.username_max_length'))
    .required(i18n.t('validator.e_invoicing.username_required'))
    .nullable(),
  eInvoicingPassword: Yup.string()
    .max(50, i18n.t('validator.e_invoicing.password_max_length'))
    .required(i18n.t('validator.e_invoicing.password_required'))
    .nullable(),
});

export default EInvoicingSchema;
