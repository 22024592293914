import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Input, FormField, Image } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { changePassword } from '../../../redux/ducks/settings';
import GCButton from '../../../components/GCButton';
import PasswordInput from '../../PasswordInput';
import schema from '../../../validation/schemas/changePasswordSchema';
import Validation from '../../../components/Validation';
import Toast from '../../Toast';
import Loader from '../../Loader';
import icons from '../../../styles/svgs';

import styles from './index.module.scss';

class ResetPassword extends React.PureComponent {
  onSubmit = (payload, { resetForm }) => {
    this.props.changePassword(payload);

    resetForm();
  };

  componentDidUpdate(prevProps) {
    const { t, loading, error, history } = this.props;

    if (prevProps.loading && !loading && !error) {
      toast.success(<Toast text={t('settings.change')} />);
      history.push('/home');
    }
  }

  render() {
    const { t, loading } = this.props;

    return (
      <div id={styles.change_password}>
        <Loader loading={loading} />
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
            oldPassword: '',
          }}
          validationSchema={schema}
          onSubmit={this.onSubmit}
          render={props => {
            const { values } = props;

            return (
              <Form className="ui form">
                <div>
                  <Image
                    src={icons.ChangePassword}
                    centered
                    className={styles.img}
                  />
                  <FormField>
                    <label htmlFor="oldPassword" className={styles.label}>
                      <span>{t('settings.oldPassword')}</span>
                    </label>
                    <Validation name="oldPassword" showMessage={true}>
                      <PasswordInput
                        value={values.oldPassword}
                        name="oldPassword"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <FormField>
                    <label htmlFor="password" className={styles.label}>
                      <span>{t('settings.password')}</span>
                    </label>
                    <Validation name="password" showMessage={true}>
                      <PasswordInput
                        value={values.password}
                        name="password"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <FormField>
                    <label
                      htmlFor="passwordConfirmation"
                      className={styles.label}
                    >
                      <span>{t('settings.password_confirm')}</span>
                    </label>
                    <Validation name="passwordConfirmation" showMessage={true}>
                      <Input
                        value={values.passwordConfirmation}
                        name="passwordConfirmation"
                        type="password"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <GCButton type="submit" large>
                    {t('settings.button')}
                  </GCButton>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.settings.changePassword.loading,
  error: state.settings.changePassword.error,
});

const mapDispatchToProps = dispatch => ({
  changePassword: data => dispatch(changePassword(data)),
});

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResetPassword);
