import React, { Component } from 'react';
import classnames from 'classnames';
import { getImagePath } from '../../helpers/common';
import icons from '../../styles/svgs';
import styles from './index.module.scss';

class Avatar extends Component {
  roundedBorderRadius = 100;

  getImageOrDefault = (imageSrc, defaultIcon) => {
    if (imageSrc) {
      return getImagePath(imageSrc);
    }

    return defaultIcon ? icons[defaultIcon] : icons.Avatar;
  };

  getBorderRadiusOrDefault = rounded => {
    return rounded ? this.roundedBorderRadius : 0;
  };

  render() {
    const { src, rounded, className, defaultIcon } = this.props;

    const avatarBorderRadius = this.getBorderRadiusOrDefault(rounded);

    return (
      <img
        alt="avatar"
        src={this.getImageOrDefault(src, defaultIcon)}
        style={{
          borderRadius: `${avatarBorderRadius}`,
        }}
        className={classnames(styles.profile_image, className)}
      />
    );
  }
}

export default Avatar;
