import axios from 'axios';
import { API_URL } from '../../config';
import { businessInfoFulfilled } from './business';

export const CHANGE_PASSWORD_REQUEST =
  'globalcredit-smb/settings/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_RESPONSE =
  'globalcredit-smb/settings/CHANGE_PASSWORD_RESPONSE';
export const CHANGE_PASSWORD_ERROR =
  'globalcredit-smb/settings/CHANGE_PASSWORD_ERROR';

export const SET_E_INVOICING_DATA_REQUEST =
  'globalcredit-smb/settings/SET_E_INVOICING_DATA_REQUEST';
export const SET_E_INVOICING_DATA_RESPONSE =
  'globalcredit-smb/settings/SET_E_INVOICING_DATA_RESPONSE';
export const SET_E_INVOICING_DATA_ERROR =
  'globalcredit-smb/settings/SET_E_INVOICING_DATA_ERROR';

export const UPLOAD_ORGANIZATION_LOGO_REQUEST =
  'globalcredit-smb/settings/UPLOAD_ORGANIZATION_LOGO_REQUEST';
export const UPLOAD_ORGANIZATION_LOGO_RESPONSE =
  'globalcredit-smb/settings/UPLOAD_ORGANIZATION_LOGO_RESPONSE';
export const UPLOAD_ORGANIZATION_LOGO_ERROR =
  'globalcredit-smb/settings/UPLOAD_ORGANIZATION_LOGO_ERROR';

export const changePasswordRequest = () => ({
  type: CHANGE_PASSWORD_REQUEST,
});

export const changePasswordResponse = payload => ({
  type: CHANGE_PASSWORD_RESPONSE,
  payload,
});

export const changePasswordError = payload => ({
  type: CHANGE_PASSWORD_ERROR,
  payload,
});

export const setEInvoicingDataRequest = () => ({
  type: SET_E_INVOICING_DATA_REQUEST,
});

export const setEInvoicingDataResponse = () => ({
  type: SET_E_INVOICING_DATA_RESPONSE,
});

export const setEInvoicingDataError = payload => ({
  type: SET_E_INVOICING_DATA_ERROR,
  payload,
});

export const uploadOrganizationLogoRequest = () => ({
  type: UPLOAD_ORGANIZATION_LOGO_REQUEST,
});

export const uploadOrganizationLogoResponse = () => ({
  type: UPLOAD_ORGANIZATION_LOGO_RESPONSE,
});

export const uploadOrganizationLogoError = payload => ({
  type: UPLOAD_ORGANIZATION_LOGO_ERROR,
  payload,
});

const initialState = {
  changePassword: {
    data: [],
    loading: false,
    error: null,
  },
  eInvoicing: {
    loading: false,
    error: null,
  },
  organization: {
    loading: false,
    error: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: true,
        },
      };
    case CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: action.payload,
        },
      };
    case SET_E_INVOICING_DATA_REQUEST:
      return {
        ...state,
        eInvoicing: {
          ...state.eInvoicing,
          loading: true,
        },
      };
    case SET_E_INVOICING_DATA_RESPONSE:
      return {
        ...state,
        eInvoicing: {
          loading: false,
          error: null,
        },
      };
    case SET_E_INVOICING_DATA_ERROR:
      return {
        ...state,
        eInvoicing: {
          loading: false,
          error: action.payload,
        },
      };
    case UPLOAD_ORGANIZATION_LOGO_REQUEST:
      return {
        ...state,
        organization: {
          ...state.eInvoicing,
          loading: true,
        },
      };
    case UPLOAD_ORGANIZATION_LOGO_RESPONSE:
      return {
        ...state,
        organization: {
          loading: false,
          error: null,
        },
      };
    case UPLOAD_ORGANIZATION_LOGO_ERROR:
      return {
        ...state,
        organization: {
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

export const changePassword = data => {
  return dispatch => {
    dispatch(changePasswordRequest());

    axios
      .post(`${API_URL}/change-password`, data)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        return dispatch(changePasswordResponse(data));
      })
      .catch(error => {
        return dispatch(changePasswordError(error));
      });
  };
};

export const setEInvoicingData = data => {
  return dispatch => {
    dispatch(setEInvoicingDataRequest());

    axios
      .patch(`${API_URL}/business`, data)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        dispatch(businessInfoFulfilled(data));
        dispatch(setEInvoicingDataResponse());
      })
      .catch(error => {
        dispatch(setEInvoicingDataError(error.message));
      });
  };
};

export const uploadOrganizationLogo = payload => {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(uploadOrganizationLogoRequest());

        const { data } = await axios.patch(`${API_URL}/business`, payload);

        dispatch(businessInfoFulfilled(data.data));
        dispatch(uploadOrganizationLogoResponse());
        resolve();
      } catch (error) {
        dispatch(uploadOrganizationLogoError(error));
        reject(error);
      }
    });
  };
};
