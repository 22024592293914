export const MODAL_OPEN = 'globalcredit-smb/modal/MODAL_OPEN';
export const MODAL_CLOSE = 'globalcredit-smb/modal/MODAL_CLOSE';

export const open = (content, params) => ({
  type: MODAL_OPEN,
  payload: {
    content,
    params,
  },
});

export const close = () => ({
  type: MODAL_CLOSE,
});

const initialState = {
  isOpen: false,
  content: null,
  params: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        content: action.payload.content,
        params: action.payload.params,
        isOpen: true,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        params: {},
        isOpen: false,
      };
    default:
      return state;
  }
}

export const openModal = (content, params) => {
  return dispatch => {
    dispatch(open(content, params));
  };
};

export const closeModal = () => {
  return dispatch => {
    dispatch(close());
  };
};
