import React from 'react';
import { Image } from 'semantic-ui-react';
import _map from 'lodash/map';

import i18n from '../../i18n';
import icons from '../../styles/svgs';
import { TRANSFER_TYPE_KEYS } from '../../constants/transferStates';
import styles from './index.module.scss';

const transferTypesList = [
  {
    key: TRANSFER_TYPE_KEYS.e_invoice,
    icon: 'EInvoicing2',
    text: i18n.t('transfer_types.e_invoicing'),
    pathname: 'e-invoicing-list',
  },
  {
    key: TRANSFER_TYPE_KEYS.deposit,
    icon: 'Deposit',
    text: i18n.t('transfer_types.deposit'),
  },
  {
    key: TRANSFER_TYPE_KEYS.freeze,
    icon: 'Freeze',
    text: i18n.t('transfer_types.freeze'),
  },
  {
    key: TRANSFER_TYPE_KEYS.unfreeze,
    icon: 'Unfreeze',
    text: i18n.t('transfer_types.unfreeze'),
    pathname: 'frozen-transactions',
  },
];

const TransferTypes = ({ history, location }) => {
  const onSelectTransferType = ({ key, pathname = 'new-transfer' }) => {
    const search = location.search + `&mode=${key}`;
    history.push({ pathname: pathname, search });
  };

  return (
    <div id={styles.transfer_types}>
      {_map(transferTypesList, item => {
        return (
          <div
            key={item.key}
            className={styles.transfer_type}
            onClick={() => onSelectTransferType(item)}
          >
            <div className={styles.content}>
              <Image src={icons[item.icon]} className={styles.arrow_img} />
              <p>{item.text}</p>
            </div>
            <div>
              <Image src={icons.HistoryArrow} className={styles.arrow_img} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TransferTypes;
