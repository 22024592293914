import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { API_URL } from '../../config';
import axios from 'axios';
import Speedometer from '../Speedometer';
import VendorGrid from '../VendorGrid';
import BusinessInfo from '../BusinessInfo';
import Loader from '../Loader';

import styles from './index.module.scss';
import _isEmpty from 'lodash/isEmpty';

class HomeScreen extends React.PureComponent {
  state = {
    loanLoading: false,
  };

  onApplyToLoan = async () => {
    this.setState({ loanLoading: true });
    axios
      .post(`${API_URL}/apply-loan`, {})
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;
        if (data.status === 'approved') {
          window.location.reload();
        } else {
          window.location.href = data.callbackUrl;
        }
      })
      .catch(error => {})
      .finally(() => {
        this.setState({ loanLoading: false });
      });
  };

  render() {
    const {
      business: {
        data: {
          loanLimit,
          nextPaymentDate,
          availableBalance,
          unavailableBalance,
          balanceUsage,
          reservedAmount = 0,
        },
      },
      profile,
      loading,
    } = this.props;
    const { loanLoading } = this.state;

    return (
      <div id={styles.home_container}>
        <Loader loading={loanLoading || loading} />
        {profile.hasBusiness ? (
          <Speedometer
            loanLimit={loanLimit}
            availableBalance={availableBalance}
            unavailableBalance={unavailableBalance}
          />
        ) : !_isEmpty(profile) ? (
          <div className={styles.apply_to_loan}>
            <Button className={styles.get_loan} onClick={this.onApplyToLoan}>
              Դիմել
            </Button>
          </div>
        ) : null}
        <BusinessInfo
          loanLimit={loanLimit}
          nextPaymentDate={nextPaymentDate}
          loading={loading}
          balanceUsage={balanceUsage}
          reservedAmount={reservedAmount}
          hasBusiness={profile.hasBusiness}
        />
        <VendorGrid />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { business } = state;
  const profile = state.userProfile.data;
  const loading = state.userProfile.loading || state.business.loading;

  return { business, profile, loading };
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps)
)(HomeScreen);
