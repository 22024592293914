import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import _ from 'lodash';

import Toast from '../../../Toast';
import DetailsScreen from '../../../Details/DetailsScreen';
import TransferDetailsForm from '../../../Details/TransferDetails/TransferDetailsForm';
import { confirmTransfer, confirmUnfreezeTransfer } from '../../../../redux/ducks/transferDetails';
import { formatLocalDate } from '../../../../helpers/common';
import { DATE_HYPHEN_FORMAT } from '../../../../constants';
import {
  TRANSFER_TYPES,
  TRANSFER_STATES,
  TRANSFER_TYPE_KEYS,
} from '../../../../constants/transferStates';

class TransferDetailsModal extends React.PureComponent {
  onSubmit = () => {
    const {
      amount,
      invoiceNumber,
      supplyDate,
      notes,
      supplier,
      vendor,
      confirmTransfer,
      confirmUnfreezeTransfer,
      type,
      location: { search },
    } = this.props;

    const payload = {
      ...(TRANSFER_TYPES[type] && { type: TRANSFER_TYPES[type] }),
      supplierId: supplier.id,
      vendorId: vendor.id,
      amount,
      invoiceNumber,
      supplyDate: formatLocalDate(supplyDate, DATE_HYPHEN_FORMAT),
      notes,
    };

    if (type === TRANSFER_TYPE_KEYS.unfreeze) {
      const { t_id } = queryString.parse(search);
      payload.state = TRANSFER_STATES.COMPLETED;

      confirmUnfreezeTransfer(t_id, payload)
        .then(() => {
          this.onSuccess();
        })
        .catch(() => {});
    } else {
      confirmTransfer(payload)
        .then(() => {
          this.onSuccess();
        })
        .catch(() => {});
    }
  };

  onSuccess = () => {
    const { transferId, history, t } = this.props;

    history.push({
      pathname: '/transfer-details',
      search: `?t_id=${transferId}`,
    });

    toast.success(<Toast text={t('transfer_details.operation_success')} />);
  };

  render() {
    const { loading } = this.props;

    const transfer = _.pick(this.props, [
      'user',
      'vendor',
      'supplier',
      'amount',
      'feeAmount',
      'totalAmount',
      'invoiceNumber',
      'transferNumber',
      'supplyDate',
      'notes',
      'showInvoiceNumber',
    ]);

    const component = () => <TransferDetailsForm transfer={transfer} onSubmit={this.onSubmit} />;

    return <DetailsScreen component={component} loading={loading} containerLabelSize="short" />;
  }
}

function mapStateToProps(state) {
  return {
    transferId: state.transferDetails.transfer?.id,
    loading: state.transferDetails.loading,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    confirmTransfer: data => dispatch(confirmTransfer(data)),
    confirmUnfreezeTransfer: (id, data) => dispatch(confirmUnfreezeTransfer(id, data)),
  };
};

export default compose(
  withRouter,
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TransferDetailsModal);
