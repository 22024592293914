import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Label, Card } from 'semantic-ui-react';

import Currency from '../../../Currency';
import { formatLocalDate } from '../../../../helpers/common';
import { DATE_FORMAT } from '../../../../constants';

import styles from './index.module.scss';

class LoanDetailsForm extends React.PureComponent {
  render() {
    const {
      details: {
        contractNo,
        totalDebtAmount,
        principalAmount,
        interestAmount,
        principalPenaltyAmount,
        nextTotalPaymentWithDebtAmt,
        nextPaymentDate,
        overduePrincipalInterestAmt,
        interestPenaltyAmount,
        overdueInterestAmt,
        overduePrincipalAmt,
        totalPenaltyAmount,
        overdueTotalAmt,
        nextTotalPaymentAmt,
      } = {},
      t,
    } = this.props;

    return (
      <div id={styles.loan_details}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div>
              {t('loan_details.contract_number')} {contractNo}
            </div>
          </div>
          <Card className={styles.card}>
            <Card.Content className={styles.content}>
              <Card.Header className={styles.header}>
                <div className={classnames(styles.details_row, styles.first)}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.payment_amount')}
                  </Label>
                  <div className={classnames(styles.details_col, styles.colored)}>
                    <Currency value={nextTotalPaymentWithDebtAmt} hasSign />
                  </div>
                </div>
              </Card.Header>
              <Card.Description className={styles.info}>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.current_payment')}
                    {nextPaymentDate ? (
                      <div className={styles.date}>
                        {`${t('loan_details.date')} ${formatLocalDate(
                          nextPaymentDate,
                          DATE_FORMAT
                        )}`}
                      </div>
                    ) : (
                      ''
                    )}
                  </Label>
                  <div className={styles.details_col}>
                    {nextTotalPaymentAmt ? <Currency value={nextTotalPaymentAmt} hasSign /> : '-'}
                  </div>
                </div>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.fines')}
                  </Label>
                  <div className={styles.details_col}>
                    <Currency value={totalPenaltyAmount} hasSign />
                  </div>
                </div>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.overdue_amount')}
                  </Label>
                  <div className={styles.details_col}>
                    <Currency value={overdueTotalAmt} hasSign />
                  </div>
                </div>
              </Card.Description>
            </Card.Content>
          </Card>
          <Card className={styles.card}>
            <Card.Content className={styles.content}>
              <Card.Description className={styles.info}>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.overdue_principal_amount')}
                  </Label>
                  <div className={styles.details_col}>
                    <Currency value={overduePrincipalAmt} hasSign />
                  </div>
                </div>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.overdue_principal_interest')}
                  </Label>
                  <div className={styles.details_col}>
                    <Currency value={overduePrincipalInterestAmt} hasSign />
                  </div>
                </div>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.fine')}
                  </Label>
                  <div className={styles.details_col}>
                    <Currency value={principalPenaltyAmount} hasSign />
                  </div>
                </div>
              </Card.Description>
            </Card.Content>
          </Card>
          <Card className={styles.card}>
            <Card.Content className={styles.content}>
              <Card.Description className={styles.info}>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.overdue_interest')}
                  </Label>
                  <div className={styles.details_col}>
                    <Currency value={overdueInterestAmt} hasSign />
                  </div>
                </div>
                <div className={styles.details_row}>
                  <Label className={classnames(styles.details_col, styles.details_col_label)}>
                    {t('loan_details.overdue_interest_fine')}
                  </Label>
                  <div className={styles.details_col}>
                    <Currency value={interestPenaltyAmount} hasSign />
                  </div>
                </div>
              </Card.Description>
            </Card.Content>
          </Card>

          <div className={styles.footer}>
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('loan_details.total_debt')}
              </Label>
              <div className={styles.details_col}>
                <Currency value={totalDebtAmount} hasSign />
              </div>
            </div>
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('loan_details.principal')}
              </Label>
              <div className={styles.details_col}>
                <Currency value={principalAmount} hasSign />
              </div>
            </div>
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('loan_details.interest')}
              </Label>
              <div className={styles.details_col}>
                <Currency value={interestAmount} hasSign />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation('translations'))(LoanDetailsForm);
