import axios from 'axios';
import { API_URL } from '../../config';

export const READ_NOTIFICATIONS_REQUEST =
  'globalcredit-smb/notifications/GET_NOTIFICATIONS_REQUEST';
export const READ_NOTIFICATIONS_RESPONSE =
  'globalcredit-smb/notifications/GET_NOTIFICATIONS_RESPONSE';
export const READ_NOTIFICATIONS_ERROR =
  'globalcredit-smb/notifications/GET_NOTIFICATIONS_ERROR';

export const UPDATE_UNREAD_NOTIFICATIONS =
  'globalcredit-smb/notifications/UPDATE_UNREAD_NOTIFICATIONS';

export const getNotificationsRequest = () => ({
  type: READ_NOTIFICATIONS_REQUEST,
});

export const getNotificationsResponse = payload => ({
  type: READ_NOTIFICATIONS_RESPONSE,
  payload,
});

export const getNotificationsError = payload => ({
  type: READ_NOTIFICATIONS_ERROR,
  payload,
});

export const updateUnreadNotifications = payload => ({
  type: UPDATE_UNREAD_NOTIFICATIONS,
  payload,
});

const initialState = {
  unreadNotifications: [],
  readNotifications: null,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case READ_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case READ_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        readNotifications: [...action.payload],
        loading: false,
      };
    case READ_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: [...action.payload],
      };
    default:
      return state;
  }
}

export const readNotifications = () => dispatch => {
  dispatch(getNotificationsRequest());

  axios
    .get(`${API_URL}/read-notifications`)
    .then(r => r.data)
    .then(response => {
      const { data } = response;

      return dispatch(getNotificationsResponse(data.notifications));
    })
    .catch(error => {
      return dispatch(getNotificationsError(error));
    });
};

export const unreadNotifications = () => dispatch => {
  axios
    .get(`${API_URL}/unread-notifications`)
    .then(r => r.data)
    .then(response => {
      const {
        data: { notifications },
      } = response;

      return dispatch(updateUnreadNotifications(notifications));
    });
};

export const markAsRead = id => (dispatch, getState) => {
  axios.post(`${API_URL}/mark-as-read`, { id }).then(r => r.data);

  const unreadNotifications = getState().notifications.unreadNotifications;
  const data = unreadNotifications.filter(el => el.id !== id);

  return dispatch(updateUnreadNotifications(data));
};
