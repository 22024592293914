import axios from 'axios';
import { API_URL } from '../../config';

export const GET_FEE_AMOUNT_REQUEST = 'globalcredit-smb/fee-amount/GET_FEE_AMOUNT_REQUEST';
export const GET_FEE_AMOUNT_RESPONSE = 'globalcredit-smb/fee-amount/GET_FEE_AMOUNT_RESPONSE';
export const GET_FEE_AMOUNT_ERROR = 'globalcredit-smb/fee-amount/GET_FEE_AMOUNT_ERROR';

export const getFeeAmountResponse = payload => ({
  type: GET_FEE_AMOUNT_RESPONSE,
  payload,
});

export const getFeeAmountError = payload => ({
  type: GET_FEE_AMOUNT_ERROR,
  payload,
});

export const getFeeAmountRequest = () => ({
  type: GET_FEE_AMOUNT_REQUEST,
});

const initialState = {
  feeAmount: undefined,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FEE_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FEE_AMOUNT_RESPONSE:
      return {
        ...state,
        feeAmount: action.payload,
        loading: false,
      };
    case GET_FEE_AMOUNT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getFeeAmount = amount => {
  return dispatch => {
    dispatch(getFeeAmountRequest());
    return axios
      .post(`${API_URL}/transfers/calculate-fee`, { amount })
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        dispatch(getFeeAmountResponse(data.feeAmount));
      })
      .catch(error => {
        dispatch(getFeeAmountError(error));
        throw Error();
      });
  };
};
