import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

const AuthHeader = ({ title }) => (
  <div className={styles.header}>
    <Link to="/login" className={styles.anchor_container}>
      <Icon name="arrow left" />
    </Link>
    {title && <p className={styles.title}>{title}</p>}
  </div>
);

export default AuthHeader;
