import React, { useEffect, useState } from 'react';
import { Modal, Icon, Button, Image } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { format, addMonths } from 'date-fns';
import queryString from 'query-string';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _min from 'lodash/min';

import { DATE_FORMAT, DATE_FORMAT_2, DATE_HYPHEN_FORMAT } from '../../../../constants';
import { isValidDate } from '../../../../helpers/isValidDate';
import { formatLocalDate } from '../../../../helpers/common';
import { getEInvoices } from '../../../../redux/ducks/EInvoices';
import { setInvoice } from '../../../../redux/ducks/transferDetails';
import icons from '../../../../styles/svgs';
import Loader from '../../../Loader';
import { useDeviceResize } from '../../../../hooks/useDeviceResize';
import Currency from '../../../Currency';

import styles from './index.module.scss';

const initialStartDate = addMonths(new Date(), -1);
const initialEndDate = addMonths(new Date(), 1);

const InvoiceListModal = ({ location, open, formikRef, transferAmount, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tabletResize = useDeviceResize('tablet');
  const { data, loading } = useSelector(state => state.EInvoices);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [inputDate, setInputDate] = useState(
    `${format(initialStartDate, DATE_FORMAT_2)} - ${format(initialEndDate, DATE_FORMAT_2)}`
  );

  useEffect(() => {
    onApplyDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setInputDate(
      `${format(new Date(start || startDate), DATE_FORMAT_2)} - ${format(
        new Date(end || endDate),
        DATE_FORMAT_2
      )}`
    );
  };

  const inputOnChange = event => {
    setInputDate(event.target.value);
  };

  const onApplyDate = () => {
    handleGetEInvoices(startDate, endDate);
  };

  const inputOnKeyDown = event => {
    const {
      target: { value },
      key,
    } = event;

    if (key === 'Enter') {
      const [startDate, endDate] = handleUpdate(value);
      handleGetEInvoices(startDate, endDate);
    }
  };

  const handleUpdate = inputDate => {
    const dates = inputDate.split(' - ').map(item => {
      const date = item.split('/');

      return {
        isValid: isValidDate(item),
        value: new Date(date[2], date[1] - 1, date[0]),
      };
    });

    const [start, end] = dates;
    const startDate = start.isValid ? start.value : initialStartDate;
    const endDate = end.isValid ? end.value : initialEndDate;

    setStartDate(startDate);
    setEndDate(endDate);

    if (!start.isValid || !end.isValid) {
      setInputDate(`${format(startDate, DATE_FORMAT_2)} - ${format(endDate, DATE_FORMAT_2)}`);
    }

    return [startDate, endDate];
  };

  const handleGetEInvoices = (startDate, endDate) => {
    const { v_id } = queryString.parse(location.search);
    const params = {
      vendorId: v_id,
      supplyStartDate: formatLocalDate(startDate, DATE_HYPHEN_FORMAT),
      supplyEndDate: formatLocalDate(endDate, DATE_HYPHEN_FORMAT),
    };

    dispatch(getEInvoices(params));
    setOpenDatePicker(false);
  };

  return (
    <Modal open={open} onClose={onClose} className={styles.invoice_list_modal}>
      <Loader loading={loading} />
      <div className={styles.header}>
        <p>{t('new-transfer.invoice_numbers')}</p>
        <Icon name="x" onClick={onClose} />
      </div>
      <div className={styles.filters}>
        <div className={styles.input_block}>
          <InputMask
            className={styles.field}
            mask="99/99/9999 - 99/99/9999"
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            value={inputDate}
            onChange={inputOnChange}
            onKeyDown={inputOnKeyDown}
            onBlur={event => handleUpdate(event.target.value)}
          />
          <div
            className={styles.icon_block}
            onClick={() => {
              setOpenDatePicker(!openDatePicker);
            }}
          >
            <Image src={icons.Calendar2} className={styles.date_icon} />
          </div>
        </div>
        <div className={styles.date_picker_block}>
          {openDatePicker && (
            <div className={styles.date_picker}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                locale="hy"
                selectsRange
                inline
                monthsShown={tabletResize ? 1 : 2}
              />
              <Button basic onClick={onApplyDate}>
                {t('new-transfer.apply')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>
        {_isEmpty(data) ? (
          <div className={styles.empty_block}>
            <Icon name="search" />
            <p>{t('new-transfer.empty_invoice_list')}</p>
          </div>
        ) : (
          <>
            {_map(data, item => {
              return (
                <div
                  key={item.invoiceNumber}
                  className={styles.content_item}
                  onClick={() => {
                    if (formikRef.current) {
                      formikRef.current.setFieldValue('invoiceNumber', item.invoiceNumber);
                      formikRef.current.setFieldValue(
                        'amount',
                        _min([transferAmount, item.amount])
                      );
                      formikRef.current.setFieldValue('invoiceAmount', item.amount);
                      formikRef.current.setFieldValue('checkInvoiceNumber', true);
                      dispatch(setInvoice(item));
                    }
                    onClose();
                  }}
                >
                  <div className={styles.item_column}>
                    <div className={styles.content_item_line}>
                      <p>{t('new-transfer.invoice_number')}</p>
                      <p className={styles.important_line}>{item.invoiceNumber}</p>
                    </div>
                    <div className={styles.content_item_line}>
                      <p>{t('new-transfer.submission')}</p>
                      <p>{formatLocalDate(item.submissionDate, DATE_FORMAT)}</p>
                    </div>
                  </div>
                  <div className={styles.item_column}>
                    <div className={styles.content_item_line}>
                      <p>{t('new-transfer.amount')}</p>
                      <Currency hasSign value={item.amount} className={styles.important_line} />
                    </div>
                    <div className={styles.content_item_line}>
                      <p>{t('new-transfer.supply')}</p>
                      <p>{formatLocalDate(item.supplyDate, DATE_FORMAT)}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </Modal>
  );
};

export default withRouter(InvoiceListModal);
