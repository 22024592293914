import React from 'react';
import querystring from 'query-string';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { List, Search, Image, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { getEInvoices } from '../../redux/ducks/EInvoices';
import { getVendors } from '../../redux/ducks/vendor';
import { vendorSelector } from '../../redux/selectors';
import WalletIcon from '../../styles/svgs/wallet.svg';
import { formatLocalDate } from '../../helpers/common';
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_HYPHEN_FORMAT } from '../../constants';

import Loader from '../Loader';
import VendorImage from '../../components/VendorImage';
import Currency from '../Currency';
import FilterModal from './FilterModal';
import styles from './index.module.scss';

class EInvoicingList extends React.PureComponent {
  state = {
    search: '',
    openSearch: false,
    openFilter: false,
    startDate: moment()
      .subtract(1, 'M')
      .toDate(),
    endDate: moment()
      .add(1, 'M')
      .toDate(),
  };

  componentDidMount() {
    const { vendor, getVendors } = this.props;

    this.getEInvoicingList();

    if (!vendor) {
      getVendors();
    }
  }

  getEInvoicingList = () => {
    const { startDate, endDate } = this.state;
    const {
      location: { search: params },
      fetchEInvoicingList,
    } = this.props;
    const { v_id } = querystring.parse(params);
    const supplyStartDate = moment(startDate).format(DATE_HYPHEN_FORMAT);
    const supplyEndDate = moment(endDate).format(DATE_HYPHEN_FORMAT);

    fetchEInvoicingList({
      vendorId: v_id,
      supplyStartDate,
      supplyEndDate,
    });
  };

  onSearchChange = (_, { value }) => {
    this.setState({ search: value });
  };

  onBack = () => {
    const { location, history } = this.props;
    const search = querystring.exclude(location.search, ['mode']);

    history.push({ pathname: 'transfer-type', search });
  };

  onOpenSearch = () => {
    this.setState({ openSearch: true });

    const searchInput = document.querySelector('.ui.search input.prompt');

    if (searchInput) {
      searchInput.focus();
    }
  };

  onHideSearch = () => {
    this.setState({ openSearch: false, search: '' });
    this.getEInvoicingList();
  };

  onClearSearch = () => {
    this.setState({ search: '' });
  };

  handleKeyPress = event => {
    const {
      key,
      target: { value },
    } = event;

    if (key === 'Enter' && value) {
      const {
        location: { search: params },
        fetchEInvoicingList,
      } = this.props;
      const { v_id } = querystring.parse(params);

      fetchEInvoicingList({ vendorId: v_id, invoiceNumber: value });
    }
  };

  onOpenFilter = () => {
    this.setState({ openFilter: true });
  };

  onCloseFilter = () => {
    this.setState({ openFilter: false });
  };

  onSaveDate = ({ from, to }) => {
    this.setState(
      {
        openFilter: false,
        startDate: from,
        endDate: to,
      },
      () => {
        this.getEInvoicingList();
      }
    );
  };

  render() {
    const { search, openSearch, openFilter, startDate, endDate } = this.state;
    const {
      t,
      location: { search: params },
      loading,
      EInvoicingData,
      vendor = {
        image: '',
        name: '',
      },
    } = this.props;

    return (
      <div id={styles.e_invoices}>
        <Loader loading={loading} />
        <div className={styles.header}>
          <div onClick={this.onBack}>
            <Icon name="arrow left" />
          </div>
          <div>{t('menu.e_invoicing')}</div>
          <div className={styles.right_side}>
            <Icon name="search" onClick={this.onOpenSearch} />
            <Icon name="calendar alternate outline" onClick={this.onOpenFilter} />
          </div>
        </div>
        <div
          className={classNames(styles.search_block, {
            [styles.openSearch]: openSearch,
          })}
        >
          <div>
            <div className={styles.search_body}>
              <Search
                input={{ icon: 'search', iconPosition: 'left' }}
                className={styles.search}
                onSearchChange={this.onSearchChange}
                onKeyPress={this.handleKeyPress}
                open={false}
                value={search}
                placeholder={t('e_invoicing.invoice_number')}
              />
              {search && <Icon name="x" onClick={this.onClearSearch} />}
            </div>
            <div className={styles.cancel} onClick={this.onHideSearch}>
              {t('e_invoicing.cancel')}
            </div>
          </div>
        </div>
        <div className={styles.title}>
          <div className={styles.vendor_info}>
            <VendorImage src={vendor.image} className={styles.vendor_logo} />
            <div>
              <p>{t('e_invoicing.vendor')}</p>
              <p className={styles.vendor_name}>{vendor.name}</p>
            </div>
          </div>
          <div className={styles.vendor_number}>
            <p>{t('e_invoicing.tin')}</p>
            <p>{vendor.tin}</p>
          </div>
        </div>
        <List>
          {EInvoicingData.map((EInvoice, index) => {
            return (
              <List.Item
                as={Link}
                key={index}
                to={{
                  pathname: '/new-transfer',
                  search: params,
                  state: {
                    invoiceNumber: EInvoice.invoiceNumber,
                    amount: EInvoice.amount,
                  },
                }}
              >
                <div className={styles.item_description}>
                  <div className={styles.row}>
                    <div className={classNames(styles.info, styles.left_container)}>
                      <div className={styles.label}>{t('e_invoicing.invoice_number')}</div>
                      <div className={styles.value}>{EInvoice.invoiceNumber}</div>
                    </div>
                    <div className={styles.info}>
                      <div className={styles.label}>{t('e_invoicing.amount')}</div>
                      <div className={styles.value}>
                        <Currency value={EInvoice.amount} precision={0} hasSign />
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={classNames(styles.info, styles.left_container)}>
                      <div className={styles.label}>{t('e_invoicing.write_off')}</div>
                      <div className={styles.date}>
                        {formatLocalDate(EInvoice.submissionDate, DATE_TIME_FORMAT)}
                      </div>
                    </div>
                    <div className={styles.info}>
                      <div className={styles.label}>{t('e_invoicing.supply')}</div>
                      <div className={styles.date}>
                        {formatLocalDate(EInvoice.supplyDate, DATE_FORMAT)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.wallet_icon}>
                  <Image src={WalletIcon} />
                </div>
              </List.Item>
            );
          })}
        </List>
        {openFilter && (
          <FilterModal
            startDate={startDate}
            endDate={endDate}
            onClose={this.onCloseFilter}
            onSave={this.onSaveDate}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    location: { search },
  } = props;
  const { v_id } = querystring.parse(search, { parseNumbers: true });
  const vendor = vendorSelector(v_id);

  return {
    EInvoicingData: state.EInvoices.data,
    vendor: vendor(state),
    loading: state.EInvoices.loading,
    error: state.EInvoices.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEInvoicingList: params => dispatch(getEInvoices(params)),
    getVendors: () => dispatch(getVendors()),
  };
};

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EInvoicingList);
