import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Input, FormField, Button, Image } from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';

import { ARMENIAN_PHONE_CODE, PHONE_NUMBER_REG_EXP } from '../../constants';
import { signUp } from '../../redux/ducks/login';
import schema from '../../validation/schemas/signUpSchema';

import GCButton from '../GCButton';
import PasswordInput from '../PasswordInput';
import icons from '../../styles/svgs';
import CopyRight from '../CopyRight';
import ContactInfo from '../ContactInfo';
import Validation from '../Validation';
import AuthHeader from '../AuthHeader';
import Loader from '../Loader';

import styles from './index.module.scss';

const SignUpScreen = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.login.signUp);

  const onSubmit = async values => {
    try {
      const phoneNumber =
        ARMENIAN_PHONE_CODE + values.phoneNumber.replace(PHONE_NUMBER_REG_EXP, '');
      const payload = { ...values, phoneNumber };

      await dispatch(signUp(payload));
      history.push('/verification');
    } catch (error) {}
  };

  return (
    <div id={styles.sign_up_screen}>
      <Loader loading={loading} />
      <AuthHeader title={t('sign_up.title')} />
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          password: '',
          passwordConfirmation: '',
          phoneNumber: '',
          email: '',
          terms: false,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        render={props => {
          const { values } = props;

          return (
            <Form className="ui form">
              <div className={styles.sign_up_container}>
                <img src={icons.Login} className={styles.logo} alt="global-credit-logo" />
                <p className={styles.form_title}>{t('sign_up.form_title')}</p>
                <FormField>
                  <label htmlFor="firstName" className={styles.label}>
                    <span>{t('sign_up.name')}</span>
                  </label>
                  <Validation name="firstName" showMessage={true}>
                    <Input value={values.firstName} name="firstName" className={styles.input} />
                  </Validation>
                </FormField>

                <FormField>
                  <label htmlFor="lastName" className={styles.label}>
                    <span>{t('sign_up.lastName')}</span>
                  </label>
                  <Validation name="lastName" showMessage={true}>
                    <Input value={values.lastName} name="lastName" className={styles.input} />
                  </Validation>
                </FormField>

                <FormField>
                  <label htmlFor="password" className={styles.label}>
                    <span>{t('sign_up.password')}</span>
                  </label>
                  <Validation name="password" showMessage={true}>
                    <PasswordInput
                      value={values.password}
                      name="password"
                      className={styles.input}
                      autoComplete="new-password"
                    />
                  </Validation>
                </FormField>

                <FormField>
                  <label htmlFor="passwordConfirmation" className={styles.label}>
                    <span>{t('sign_up.confirmPassword')}</span>
                  </label>
                  <Validation name="passwordConfirmation" showMessage={true}>
                    <PasswordInput
                      value={values.passwordConfirmation}
                      name="passwordConfirmation"
                      className={styles.input}
                      autoComplete="new-password"
                    />
                  </Validation>
                </FormField>

                <FormField>
                  <label htmlFor="phoneNumber" className={styles.label}>
                    <span>{t('sign_up.phone')}</span>
                  </label>
                  <Validation name="phoneNumber" showMessage={true}>
                    <div className={classnames(styles.input, styles.phone_input)}>
                      <div className={styles.input_prefix}>
                        <Image src={icons.ArmFlag} />
                        <p>{ARMENIAN_PHONE_CODE}</p>
                      </div>
                      <InputMask
                        name="phoneNumber"
                        mask="(99) 99 99 99"
                        placeholder="(00) 00 00 00"
                        autoComplete="off"
                      />
                    </div>
                  </Validation>
                </FormField>

                <FormField>
                  <label htmlFor="email" className={styles.label}>
                    <span>{t('sign_up.email')}</span>
                  </label>
                  <Validation name="email" showMessage={true}>
                    <Input value={values.email} name="email" className={styles.input} />
                  </Validation>
                </FormField>

                <FormField className={styles.checkbox}>
                  <Validation name="terms" showMessage={true}>
                    <label className={styles.label}>
                      <Input
                        type="checkbox"
                        value={values.terms}
                        name="terms"
                        className={styles.input}
                      />
                      <span>
                        {t('sign_up.terms')}
                        <a href="/documents/terms.pdf" target="_blank" rel="noopener noreferrer">
                          {' '}
                          {t('sign_up.terms_link')}
                        </a>
                      </span>
                    </label>
                  </Validation>
                </FormField>

                <GCButton type="submit" large>
                  {t('sign_up.title')}
                </GCButton>
                <Button
                  type="button"
                  className={styles.cancel}
                  onClick={() => history.push('/home')}
                >
                  {t('sign_up.cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <ContactInfo />
      <CopyRight />
    </div>
  );
};

export default SignUpScreen;
