import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import GCButton from '../GCButton';

class LoanUpdateInfo extends React.PureComponent {
  updateInfo = () => {
    window.location.replace('/home');
  };

  render() {
    const { t, isInProcess } = this.props;
    return (
      <Modal
        open={isInProcess}
        dimmer="inverted"
        closeOnDimmerClick={false}
        className="update-modal"
      >
        <Modal.Content>
          <p>{t('updated_info.loan_sync_process')}</p>
        </Modal.Content>
        <Modal.Actions>
          <GCButton secondary onClick={this.updateInfo}>
            {t('updated_info.refresh')}
          </GCButton>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isInProcess: !!(
    state.userProfile.data.hasBusiness &&
    state.business.data.contractNumber &&
    state.business.data.eInvoicingUsername &&
    !state.business.data.loan
  ),
});

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps)
)(LoanUpdateInfo);
