import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'formik';
import { compose } from 'redux';
import ServerError from './ServerError';

import styles from './index.module.scss';

class Validation extends Component {
  render() {
    const {
      children,
      name,
      showMessage,
      formik: { errors, touched, setFieldTouched, handleChange, handleBlur },
      serverErrors,
    } = this.props;
    const errMap = serverErrors && serverErrors.errors;
    const err = errMap || {};
    const errorsList = err[name] || [];
    return (
      <React.Fragment>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            className: classnames(child.props.className, {
              [styles.invalid]: touched[name] && errors[name],
            }),
            name: name,
            id: name,
            onChange: (args, data) => {
              setFieldTouched(name, false);
              if (typeof child.props.onChange === 'function') {
                child.props.onChange(args, data);
              } else {
                handleChange(args);
              }
            },
            onBlur: args => {
              if (typeof child.props.onBlur === 'function') {
                child.props.onBlur(args);
              } else {
                handleBlur(args);
              }
            },
          });
        })}
        {showMessage && (
          <div className={styles.error}>
            {errors[name] && touched[name] ? errors[name] : ''}
          </div>
        )}
        {errorsList.map((err, i) => {
          return <ServerError key={i} error={err} />;
        })}
      </React.Fragment>
    );
  }
}

export default compose(connect)(Validation);
