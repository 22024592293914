import axios from 'axios';

import { API_URL } from '../../config';

export const SUPPLIERS_REQUEST = 'globalcredit-smb/suppliers/SUPPLIERS_REQUEST';
export const SUPPLIERS_RESPONSE =
  'globalcredit-smb/suppliers/SUPPLIERS_RESPONSE';
export const SUPPLIERS_ERROR = 'globalcredit-smb/suppliers/SUPPLIERS_ERROR';

export const fetchSuppliers = () => ({
  type: SUPPLIERS_REQUEST,
});

export const fetchSuppliersFulfilled = payload => ({
  type: SUPPLIERS_RESPONSE,
  payload,
});

export const fetchSuppliersFailed = payload => ({
  type: SUPPLIERS_ERROR,
  payload: payload,
  error: true,
});

const initialState = {
  data: [],
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUPPLIERS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUPPLIERS_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SUPPLIERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getSuppliers = vendorId => {
  return dispatch => {
    dispatch(fetchSuppliers());

    axios
      .get(`${API_URL}/vendors/${vendorId}/suppliers`)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        return dispatch(fetchSuppliersFulfilled(data));
      })
      .catch(error => {
        return dispatch(fetchSuppliersFailed(error));
      });
  };
};
