import axios from 'axios';
import { API_URL } from '../../config';

export const VENDORS_FETCH = 'globalcredit-smb/vendors/VENDORS_FETCH';
export const VENDORS_FETCHED = 'globalcredit-smb/vendors/VENDORS_FETCHED';
export const VENDORS_ERROR = 'globalcredit-smb/vendors/VENDORS_ERROR';

export const fetchVendors = payload => ({
  type: VENDORS_FETCH,
  payload,
});

export const fetchVendorsFullFilled = payload => ({
  type: VENDORS_FETCHED,
  payload,
});

export const fetchVendorsError = payload => ({
  type: VENDORS_ERROR,
  payload,
});

const initialState = {
  data: [],
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case VENDORS_FETCH:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case VENDORS_FETCHED:
      return {
        ...state,
        data: [...action.payload],
        loading: false,
      };

    case VENDORS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export const getVendors = () => {
  return dispatch => {
    dispatch(fetchVendors());

    axios
      .get(`${API_URL}/vendors`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        return dispatch(fetchVendorsFullFilled(data));
      })
      .catch(error => {
        return dispatch(fetchVendorsError(error));
      });
  };
};
