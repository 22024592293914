import React, { useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';

const PasswordInput = ({ lockIcon = false, ...props }) => {
  const [passwordMode, setPasswordMode] = useState(true);

  return (
    <Input
      icon={
        lockIcon ? (
          <Icon name="lock" />
        ) : (
          <Icon
            name={passwordMode ? 'eye' : 'eye slash'}
            link
            onClick={() => setPasswordMode(!passwordMode)}
          />
        )
      }
      iconPosition={lockIcon ? 'left' : null}
      type={passwordMode ? 'password' : 'text'}
      {...props}
    />
  );
};

export default PasswordInput;
