import axios from 'axios';
import { API_URL } from '../../config';

const GET_TRANSACTION_REQUEST = 'globalcredit-smb/frozen_transactions/GET_TRANSACTION_REQUEST';
const GET_TRANSACTION_RESPONSE = 'globalcredit-smb/frozen_transactions/GET_TRANSACTION_RESPONSE';
const GET_TRANSACTION_ERROR = 'globalcredit-smb/frozen_transactions/GET_TRANSACTION_ERROR';
const GET_TRANSFER_REQUEST = 'globalcredit-smb/frozen_transactions/GET_TRANSFER_REQUEST';
const GET_TRANSFER_RESPONSE = 'globalcredit-smb/frozen_transactions/GET_TRANSFER_RESPONSE';
const GET_TRANSFER_ERROR = 'globalcredit-smb/frozen_transactions/GET_TRANSFER_ERROR';
const RESET = 'globalcredit-smb/frozen_transactions/RESET';

const getTransactionsRequest = () => ({
  type: GET_TRANSACTION_REQUEST,
});

const getTransactionsFulfilled = payload => ({
  type: GET_TRANSACTION_RESPONSE,
  payload,
});

const getTransactionsFailed = payload => ({
  type: GET_TRANSACTION_ERROR,
  payload,
});

export const getTransferRequest = () => ({
  type: GET_TRANSFER_REQUEST,
});

export const getTransferFulfilled = payload => ({
  type: GET_TRANSFER_RESPONSE,
  payload,
});

export const getTransferFailed = payload => ({
  type: GET_TRANSFER_ERROR,
  payload,
});

export const resetTransactions = () => ({
  type: RESET,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  transfer: {},
  transferLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSACTION_RESPONSE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case GET_TRANSACTION_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_TRANSFER_REQUEST:
      return {
        ...state,
        transferLoading: true,
      };
    case GET_TRANSFER_RESPONSE:
      return {
        ...state,
        transfer: action.payload,
        transferLoading: false,
      };
    case GET_TRANSFER_ERROR:
      return {
        ...state,
        transferLoading: false,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export const getFrozenTransactions = () => {
  return dispatch => {
    dispatch(getTransactionsRequest());

    axios
      .get(`${API_URL}/transfers?type=RESERVE&state=SUSPENDED`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        return dispatch(getTransactionsFulfilled(data));
      })
      .catch(error => {
        return dispatch(getTransactionsFailed(error));
      });
  };
};
