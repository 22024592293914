import i18n from '../../i18n';

export const transferPagesConfig = {
  e_invoice: {
    title: i18n.t('menu.new_transfer_e_invoice'),
    showEInvoiceField: true,
  },
  deposit: {
    title: i18n.t('menu.new_transfer_deposit'),
    supplyDateField: true,
    showInvoiceNumber: false,
  },
  freeze: {
    title: i18n.t('menu.new_transfer_freeze'),
    supplyDateField: true,
    showInvoiceNumber: false,
  },
  unfreeze: {
    title: i18n.t('menu.new_transfer_unfreeze'),
    showTransferNumber: true,
    showEInvoiceField: true,
    showEInvoiceList: true,
    showAmountWarning: true,
    showMinAmount: true,
  },
};
