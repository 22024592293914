import axios from 'axios';
import querystring from 'query-string';
import { API_URL } from '../../config';

export const E_INVOICES_REQUEST = 'globalcredit-smb/e-invoices/E_INVOICES_REQUEST';
export const E_INVOICE_REQUEST = 'globalcredit-smb/e-invoices/E_INVOICE_REQUEST';
export const E_INVOICES_RESPONSE = 'globalcredit-smb/e-invoices/E_INVOICES_RESPONSE';
export const E_INVOICES_ERROR = 'globalcredit-smb/e-invoices/E_INVOICES_ERROR';

export const fetchEInvoices = () => ({
  type: E_INVOICES_REQUEST,
});

export const fetchEInvoicesFulfilled = payload => ({
  type: E_INVOICES_RESPONSE,
  payload,
});

export const fetchEInvoicesFailed = payload => ({
  type: E_INVOICES_ERROR,
  payload: payload,
});

const initialState = {
  data: [],
  loading: false,
  error: null,
  invoice: {},
  invoiceLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case E_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        invoice: {},
      };
    case E_INVOICES_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case E_INVOICES_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getEInvoices = ({ vendorId, invoiceNumber, supplyStartDate, supplyEndDate }) => {
  return dispatch => {
    dispatch(fetchEInvoices());

    const queryParams = querystring.stringify({
      vendor_id: vendorId,
      invoice_number: invoiceNumber,
      supply_start_date: supplyStartDate,
      supply_end_date: supplyEndDate,
    });

    return axios
      .get(`${API_URL}/invoices?${queryParams}`)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        dispatch(fetchEInvoicesFulfilled(data));
        return data;
      })
      .catch(error => {
        return dispatch(fetchEInvoicesFailed(error));
      });
  };
};
