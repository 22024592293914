import React from 'react';
import MobileContainer from './MobileContainer';

const ResponsiveContainer = ({ children, profile, business, routes }) => (
  <MobileContainer profile={profile} business={business} routes={routes}>
    {children}
  </MobileContainer>
);

export default ResponsiveContainer;
