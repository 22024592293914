import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getTransfer } from '../../../redux/ducks/transferDetails';
import TransferDetailsForm from '../TransferDetails/TransferDetailsForm';
import DetailsScreen from '../DetailsScreen';

class TransferHistoryDetails extends Component {
  componentDidMount() {
    const {
      match: { params },
      getTransfer,
    } = this.props;

    if (params.t_id) {
      getTransfer(params.t_id);
    }
  }

  render() {
    const { loading, transferDetails } = this.props;

    const component = () => <TransferDetailsForm transfer={transferDetails} />;

    return <DetailsScreen component={component} loading={loading} containerLabelSize="short" />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTransfer: t_id => dispatch(getTransfer(t_id)),
  };
};

function mapStateToProps({ transferDetails: { data = {}, loading } }, { match: { params } }) {
  const id = parseInt(params.t_id);
  const transferDetails = data.id === id ? data : undefined;

  return {
    transferDetails,
    loading,
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TransferHistoryDetails);
