import React from 'react';
import { connect } from 'react-redux';
import { Image, List } from 'semantic-ui-react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Loader from '../Loader';
import { DATE_TIME_FORMAT } from '../../constants';
import { formatLocalDate } from '../../helpers/common';
import { readNotifications } from '../../redux/ducks/notifications';
import icons from '../../styles/svgs';

import styles from './index.module.scss';

class NotificationsScreen extends React.PureComponent {
  componentDidMount() {
    this.props.getNotifications();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.unreadNotifications.length &&
      this.props.unreadNotifications.length === 0
    ) {
      this.props.getNotifications();
    }
  }

  render() {
    const { notifications, loading, t } = this.props;

    if (notifications && !notifications.length) {
      return <div className={styles.empty}>{t('notifications.empty')}</div>;
    }

    return (
      <div id={styles.notifications}>
        <Loader loading={loading} />
        <List>
          {notifications &&
            notifications.map(notification => {
              return (
                <List.Item key={notification.id}>
                  <List.Content>
                    <List.Header className={styles.header}>
                      <Image
                        src={icons.Time}
                        floated="left"
                        className={styles.icon}
                      />
                      {formatLocalDate(notification.createdAt, DATE_TIME_FORMAT)}
                    </List.Header>
                    <List.Description className={styles.description}>
                      {notification.text}
                    </List.Description>
                  </List.Content>
                </List.Item>
              );
            })}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications.readNotifications,
  unreadNotifications: state.notifications.unreadNotifications,
  loading: state.notifications.loading,
});

const mapDispatchToProps = dispatch => ({
  getNotifications: () => dispatch(readNotifications()),
});

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NotificationsScreen);
