import querystring from 'query-string';
import i18next from 'i18next';

import HomeScreen from '../../components/HomeScreen';
import SuppliersScreen from '../../components/SuppliersScreen';
import TransferDetails from '../../components/Details/TransferDetails';
import NewTransferScreen from '../../components/NewTransferScreen';
import TransferHistory from '../../components/History/TransferHistory';
import TransferHistoryDetails from '../../components/Details/TransferHistoryDetails';
import PaymentHistory from '../../components/History/PaymentHistory';
import NotificationScreen from '../../components/NotificationsScreen';
import LoanDetails from '../../components/Details/LoanDetails';
import ChangePasswordSetting from '../../components/SettingsScreen/ChangePassword';
import EInvoicingSetting from '../../components/SettingsScreen/EInvoicing';
import BusinessAccountSetting from '../../components/SettingsScreen/BusinessAccount';
import EInvoicingList from '../../components/EInvoicingList';
import TransferTypes from '../../components/TransferTypes';
import FrozenTransactions from '../../components/FrozenTransactions';
import VerificationScreen from '../../components/VerificationScreen';

import { transferPagesConfig } from '../../components/NewTransferScreen/config';
import { TRANSFER_TYPE_KEYS } from '../../constants/transferStates';

const scrollPageConfig = [
  {
    pathname: 'transfer-history',
    from: 'transfer-history-details',
  },
  {
    pathname: 'payment-history',
    from: 'payment-history-details',
  },
];

const findElement = (location, key) =>
  scrollPageConfig.find(elem => {
    const locationPath = location.pathname.split('/')[1];

    return locationPath === elem[key];
  });

const routes = ({
  location,
  history,
  transferHistory,
  paymentHistory,
  transferDetails,
  notifications,
  business,
}) => {
  const transferNumber = transferDetails ? transferDetails.transferNumber : '';

  history.listen(location => {
    const fromPage = findElement(location, 'from');
    const elemPath = findElement(location, 'pathname');

    //Do not reset scrolling in from pages for keeping scroll position in main history pages
    if (!(fromPage || (location.state && location.state.keepScrollPosition && elemPath))) {
      window.scrollTo(0, 0);
    }
  });

  const dependentRoutes = business.isContractExpired
    ? []
    : [
        {
          path: '/new-transfer',
          component: NewTransferScreen,
          title: (() => {
            let { mode } = querystring.parse(location.search);

            if (!transferPagesConfig[mode]) {
              mode = TRANSFER_TYPE_KEYS.e_invoice;
            }
            return transferPagesConfig[mode].title;
          })(),
        },
      ];

  return [
    {
      path: '/home',
      component: HomeScreen,
      rightBtn: () => window.location.reload(),
      businessRequired: false,
    },
    {
      path: '/vendors/:v_id/suppliers',
      component: SuppliersScreen,
      title: i18next.t('menu.suppliers'),
    },
    {
      path: '/e-invoicing-list',
      component: EInvoicingList,
      hideHeader: true,
      businessRequired: true,
    },
    {
      path: '/transfer-type',
      component: TransferTypes,
      title: i18next.t('menu.transfer_type'),
      leftBtn: location => {
        const { v_id } = querystring.parse(location.search);
        history.push(`/vendors/${v_id}/suppliers`);
      },
      businessRequired: true,
    },
    {
      path: '/frozen-transactions',
      component: FrozenTransactions,
      title: i18next.t('menu.frozen_transactions'),
      leftBtn: () => history.goBack(),
      businessRequired: true,
    },
    {
      path: '/transfer-details',
      component: TransferDetails,
      title: i18next.t('menu.transfer-number', { t_id: transferNumber }),
      leftBtn: () => history.push('/'),
      businessRequired: true,
    },
    {
      path: '/transfer-history',
      component: TransferHistory,
      title: `${i18next.t('menu.transfer-history')} (${
        transferHistory ? transferHistory.length : 0
      })`,
      businessRequired: true,
    },
    {
      path: '/transfer-history-details/:t_id',
      component: TransferHistoryDetails,
      title: i18next.t('menu.transfer-details'),
      leftBtn: () =>
        history.push({
          pathname: '/transfer-history',
          state: { keepScrollPosition: true },
        }),
      businessRequired: true,
    },
    {
      path: '/payment-history',
      component: PaymentHistory,
      title: `${i18next.t('menu.payment-history')} (${paymentHistory ? paymentHistory.length : 0})`,
      businessRequired: true,
    },
    {
      path: '/loan-details',
      component: LoanDetails,
      leftBtn: () => history.push('/'),
      businessRequired: true,
    },
    {
      path: '/notifications',
      component: NotificationScreen,
      title: `${i18next.t('menu.notifications')} (${notifications ? notifications.length : 0})`,
      businessRequired: true,
    },
    {
      path: '/change-password',
      component: ChangePasswordSetting,
      title: i18next.t('menu.change_password'),
      businessRequired: false,
    },
    {
      path: '/e-invoicing',
      component: EInvoicingSetting,
      title: i18next.t('menu.e_invoicing'),
      businessRequired: true,
    },
    {
      path: '/business-account',
      component: BusinessAccountSetting,
      title: i18next.t('menu.business_account'),
      businessRequired: true,
    },
    {
      path: '/verification',
      component: VerificationScreen,
      hideHeader: true,
      businessRequired: false,
    },
    ...dependentRoutes,
  ];
};

export default routes;
