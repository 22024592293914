import i18n from '../i18n';

export const STATES_COLOR = {
  SUSPENDED: 'pending',
  COMPLETED: 'success',
  REJECTED: 'error',
  MISMATCHED: 'warning',
  PENDING_MATCHING: 'pending',
};

export const TRANSFER_TYPES_TEXT = {
  INVOICE: i18n.t('transfer-history.invoice'),
  PREPAYMENT: i18n.t('transfer-history.prepayment'),
  RESERVE: i18n.t('transfer-history.reserve'),
};

export const TRANSFER_STATES_TEXT = {
  SUSPENDED: i18n.t('transfer-history.suspended'),
  COMPLETED: i18n.t('transfer-history.completed'),
  REJECTED: i18n.t('transfer-history.rejected'),
  MISMATCHED: i18n.t('transfer-history.mismatched'),
  PENDING_MATCHING: i18n.t('transfer-history.pending_matching'),
};

export const TRANSFER_TYPES = {
  e_invoice: 'INVOICE',
  deposit: 'PREPAYMENT',
  freeze: 'RESERVE',
};

export const TRANSFER_STATES = {
  SUSPENDED: 'SUSPENDED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  MISMATCHED: 'MISMATCHED',
  PENDING_MATCHING: 'PENDING_MATCHING',
};

export const TRANSFER_TYPE_KEYS = {
  e_invoice: 'e_invoice',
  deposit: 'deposit',
  freeze: 'freeze',
  unfreeze: 'unfreeze',
};
