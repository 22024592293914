import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Image, TextArea, FormField, Input } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import queryString from 'query-string';
import _min from 'lodash/min';
import * as Yup from 'yup';
import { debounce } from 'lodash';

import { getCurrentInvoice, setInvoice } from '../../../../redux/ducks/transferDetails';
import { getFeeAmount } from '../../../../redux/ducks/feeAmount';
import { numberToDecimalString } from '../../../../helpers/numberHelpers';
import { DATE_FORMAT } from '../../../../constants';
import newTransferSchema from '../../../../validation/schemas/newTransferSchema';
import Validation from '../../../../components/Validation';
import NumberInput from '../../../../components/NumberInput';
import GCButton from '../../../../components/GCButton';
import icons from '../../../../styles/svgs';
import InvoiceListModal from '../InvoiceListModal';
import Loader from '../../../Loader';

import styles from '../../index.module.scss';

const TransferForm = ({
  location,
  initialValues,
  validationData,
  transferPage,
  disabledButton,
  onOpenConfirmModal,
  onBack,
}) => {
  let formikRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { invoiceLoading, invoice } = useSelector(state => state.transferDetails);
  const { feeAmount } = useSelector(state => state.feeAmount);
  const [openInvoiceList, setOpenInvoiceList] = useState(false);
  const { invoiceNumber = '', amount = '' } = initialValues;

  useEffect(() => {
    if (invoice.amount && formikRef.current) {
      formikRef.current.setFieldValue('invoiceNumber', invoice.invoiceNumber);
      formikRef.current.setFieldValue('invoiceAmount', invoice.amount);
      dispatch(getFeeAmount(invoice.amount));
    }
  }, [dispatch, invoice]);

  useEffect(() => {
    if (invoiceNumber) {
      dispatch(setInvoice(initialValues));
    }
  }, [dispatch, invoiceNumber, initialValues]);

  const onOpen = open => {
    setOpenInvoiceList(open);
  };

  const handleKeyUp = props => {
    const { setFieldValue, values } = props;

    if (invoice.invoiceNumber !== values.invoiceNumber) {
      Yup.reach(newTransferSchema(validationData, true), 'invoiceNumber')
        .validate(values.invoiceNumber)
        .then(() => {
          const { v_id } = queryString.parse(location.search);
          const params = {
            vendorId: v_id,
            invoiceNumber: values.invoiceNumber,
          };
          dispatch(getCurrentInvoice(params))
            .then(response => {
              const [invoice = {}] = response;
              if (invoice.amount) {
                let finalAmount = invoice.amount;
                if (transferPage.showMinAmount) {
                  finalAmount = _min([amount, invoice.amount]);
                }

                setFieldValue('amount', finalAmount);
                setFieldValue('invoiceAmount', invoice.amount);
                setFieldValue('checkInvoiceNumber', true);
              } else {
                setFieldValue('checkInvoiceNumber', false);
              }
            })
            .catch(() => {
              setFieldValue('checkInvoiceNumber', false);
            });
        })
        .catch(() => {
          if (values.invoiceAmount) {
            setFieldValue('invoiceAmount', '');
          }
        });
    } else {
      setFieldValue('invoiceAmount', invoice.amount);
    }
  };

  const debouncedGetFeeAmount = debounce(amount => {
    amount && dispatch(getFeeAmount(amount));
  }, 700);

  return (
    <>
      <Loader loading={invoiceLoading} />
      <Formik
        enableReinitialize
        initialValues={{
          amount,
          invoiceNumber,
          supplyDate: '',
          notes: '',
          checkInvoiceNumber: true,
        }}
        onSubmit={onOpenConfirmModal}
        validationSchema={newTransferSchema(validationData, feeAmount)}
        render={props => {
          const { values, setFieldValue } = props;
          formikRef.current = props;

          return (
            <Form className="ui form">
              <FormField>
                <label htmlFor="amount" className={styles.label_info}>
                  <span>{t('new-transfer.amount')}</span>
                  {feeAmount && values.amount ? (
                    <span className={styles.fee_amount}>
                      {t('new-transfer.fee_amount')} {numberToDecimalString(feeAmount)}
                    </span>
                  ) : (
                    ''
                  )}
                </label>
                <Validation name="amount" showMessage>
                  <NumberInput
                    className={styles.number_input}
                    value={values.amount}
                    name="amount"
                    amountWarning={!!values.amount && values.amount < values.invoiceAmount}
                    popupContent={t('new-transfer.warning')}
                    inputInfo={t('new-transfer.currency')}
                    onChange={amount => {
                      setFieldValue('amount', amount);
                      debouncedGetFeeAmount(amount);
                    }}
                  />
                </Validation>
              </FormField>

              {transferPage.showEInvoiceField && (
                <FormField>
                  <label htmlFor="invoiceNumber" className={styles.label_info}>
                    <span>{t('new-transfer.invoice_number')}</span>
                  </label>
                  <Validation name="invoiceNumber" showMessage>
                    <div className={styles.field}>
                      <Input
                        className={styles.number_input}
                        name="invoiceNumber"
                        value={values.invoiceNumber}
                        onKeyUp={() => handleKeyUp(props)}
                      />
                      {transferPage.showEInvoiceList && (
                        <div className={styles.icon_block} onClick={() => onOpen(true)}>
                          <Image src={icons.List} className={styles.list_icon} />
                        </div>
                      )}
                    </div>
                  </Validation>
                </FormField>
              )}

              {transferPage.supplyDateField && (
                <FormField>
                  <label htmlFor="supplyDate" className={styles.label_info}>
                    <span>{t('new-transfer.supplyDate')}</span>
                  </label>
                  <Validation name="supplyDate" showMessage>
                    <div className={styles.field}>
                      <label>
                        <DatePicker
                          id="date"
                          selected={values.supplyDate}
                          onChange={date => setFieldValue('supplyDate', date)}
                          popperModifiers={[
                            {
                              name: 'arrow',
                              options: {
                                padding: 100,
                              },
                            },
                          ]}
                          locale="hy"
                          dateFormat="dd/MM/yyyy"
                          placeholderText={DATE_FORMAT}
                          customInput={<InputMask mask="99/99/9999" />}
                        />
                        <div className={styles.icon_block}>
                          <Image src={icons.Calendar2} className={styles.date_icon} />
                        </div>
                      </label>
                    </div>
                  </Validation>
                </FormField>
              )}

              <FormField>
                <label htmlFor="notes" className={styles.label_info}>
                  <span>{t('new-transfer.notes')}</span>
                </label>
                <Validation name="notes" showMessage>
                  <div>
                    <TextArea value={values.notes} name="notes" className={styles.notes_input} />
                  </div>
                </Validation>
              </FormField>

              <GCButton type="submit" large action disabled={disabledButton}>
                {t('new-transfer.button')}
              </GCButton>
              <GCButton type="button" secondary className={styles.back_button} onClick={onBack}>
                {t('new-transfer.back')}
              </GCButton>
            </Form>
          );
        }}
      />
      {transferPage.showEInvoiceList && openInvoiceList && (
        <InvoiceListModal
          open={openInvoiceList}
          formikRef={formikRef}
          transferAmount={amount}
          onClose={() => onOpen(false)}
        />
      )}
    </>
  );
};

export default withRouter(TransferForm);
