import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { getVendors } from '../../redux/ducks/vendor';
import Loader from '../Loader';
import VendorItem from './VendorItem';
import styles from './index.module.scss';

class VendorGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllowFetch: true,
    };
  }

  componentDidMount() {
    const {
      profile: { hasBusiness },
      fetchVendors,
    } = this.props;

    if (hasBusiness) {
      fetchVendors();
    }
  }

  componentDidUpdate() {
    const {
      profile: { hasBusiness },
      fetchVendors,
    } = this.props;

    if (hasBusiness && this.state.isAllowFetch) {
      fetchVendors();
      this.setState({
        isAllowFetch: false,
      });
    }
  }

  render() {
    const { t, vendors, loading } = this.props;

    return (
      <div className={styles.vendor_container}>
        <p className={styles.vendor_header}>
          {t('vendor.vendors')} ({vendors.length})
        </p>
        <Loader loading={loading} />
        <div className={styles.vendor_items_container}>
          {vendors.map(v => {
            return v.suppliers.length > 0 ? (
              <Link className={styles.vendor_item} key={v.id} to={`/vendors/${v.id}/suppliers`}>
                <VendorItem image={v.image} length={v.suppliers.length} name={v.name} />
              </Link>
            ) : (
              <div className={styles.vendor_item} key={v.id}>
                <VendorItem image={v.image} length={v.suppliers.length} name={v.name} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  vendors: state.vendor.data,
  loading: state.vendor.loading,
  profile: state.userProfile.data,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchVendors: () => dispatch(getVendors()),
  };
};

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(VendorGrid);
