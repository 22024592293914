import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Accordion, Icon, Image, Menu } from 'semantic-ui-react';
import styles from './index.module.scss';

class Index extends PureComponent {
  state = { activeIndex: 1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { title, menuItems, toggle, isActive, icon, t, history, profile } = this.props;
    return (
      <Accordion id={styles.sub_menu}>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
          className={styles.title}
        >
          <Image src={icon} className={styles.img} />
          <span> {t(`menu.${title}`)}</span>
          <Icon
            name={`angle ${activeIndex === 0 ? 'down' : 'right'}`}
            size="big"
            className={styles.arrow_icon}
          />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0} className={styles.content}>
          {menuItems.map((item, index) => {
            if (item.businessRequired && !profile.hasBusiness) return null;
            return (
              <Menu.Item
                active={isActive(item)}
                key={index}
                as="a"
                onClick={() => {
                  history.push(`/${item.url}`);
                  toggle();
                }}
                className={styles.menu_item}
              >
                <div className={styles.item_block}>
                  <div>
                    <Image src={item.icon} className={styles.menu_item_icon} />
                  </div>
                  <span>{t(`menu.${item.name}`)}</span>
                </div>
              </Menu.Item>
            );
          })}
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default compose(
  withTranslation('translations'),
  withRouter
)(Index);
