import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';
import { getImagePath } from '../../helpers/common';
import icons from '../../styles/svgs';
import styles from './index.module.scss';

class VendorImage extends Component {
  getImageOrDefault = src => {
    return src ? getImagePath(src) : icons.VendorDefault;
  };

  render() {
    const { className, src } = this.props;

    return (
      <div className={styles.vendor_image_container}>
        <Image
          alt="vendor_avatar"
          className={classNames(className, styles.vendor_image)}
          src={this.getImageOrDefault(src)}
        />
      </div>
    );
  }
}

export default VendorImage;
