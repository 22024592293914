import { createSelector } from 'reselect';

const vendorsSelector = state => state.vendor.data;

export const vendorSelector = vendorId =>
  createSelector(
    vendorsSelector,
    vendors => vendors.find(vend => vend.id === vendorId)
  );

const suppliersSelector = vendorId =>
  createSelector(
    vendorSelector(vendorId),
    vendor => vendor && vendor.suppliers
  );

export const supplierSelector = (vendorId, supplierId) =>
  createSelector(
    suppliersSelector(vendorId),
    suppliers => suppliers && suppliers.find(sup => sup.id === supplierId)
  );
