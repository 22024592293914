import React from 'react';
import Icons from '../../styles/svgs';
import { Image } from 'semantic-ui-react';
import styles from './index.module.scss';

const Toast = ({ error, text }) => (
  <div className={styles.toast}>
    <Image src={error ? Icons.Error : Icons.Success} />
    <div className={styles[error ? 'error' : 'success']}>{text}</div>
  </div>
);

export default Toast;
