import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _map from 'lodash/map';

import { getFrozenTransactions, resetTransactions } from '../../redux/ducks/frozenTransactions';
import VendorImage from '../VendorImage';
import Currency from '../Currency';
import { DATE_TIME_FORMAT, LONG_DASH } from '../../constants';
import icons from '../../styles/svgs';
import { formatLocalDate } from '../../helpers/common';
import Loader from '../Loader';

import styles from './index.module.scss';

const FrozenTransactions = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, loading } = useSelector(state => state.frozenTransactions);

  useEffect(() => {
    dispatch(getFrozenTransactions());

    return () => {
      dispatch(resetTransactions());
    };
  }, [dispatch]);

  return (
    <List>
      <Loader loading={loading} />
      {_map(data, item => {
        return (
          <List.Item
            key={item.id}
            id={styles.item}
            as={Link}
            to={{
              pathname: '/new-transfer',
              search: `${location.search}&t_id=${item.id}`,
              state: {
                amount: item.amount,
              },
            }}
          >
            <List.Header className={styles.list_header}>
              {formatLocalDate(item.date, DATE_TIME_FORMAT)}
            </List.Header>
            <List.Content className={styles.content}>
              <VendorImage src={item.vendor.image} className={styles.image} />
              <List.Description className={styles.item_description}>
                <div className={styles.row}>
                  <div className={classNames(styles.info, styles.left_container)}>
                    <div className={styles.label}>{t('transfer-history.vendor')}</div>
                    <div className={styles.value}>{item.vendor.name}</div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.label}>{t('transfer-history.transfer_id')}</div>
                    <div className={styles.value}>{item.transferNumber}</div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={classNames(styles.info, styles.left_container)}>
                    <div className={styles.label}>{t('new-transfer.supply')}</div>
                    <div className={styles.value}>{item.supplyDate || LONG_DASH}</div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.label}>{t('new-transfer.amount')}</div>
                    <div className={classNames(styles.value, styles.amount)}>
                      <Currency value={item.amount} hasSign />
                    </div>
                  </div>
                </div>
              </List.Description>
              <Image src={icons.HistoryArrow} className={styles.arrow} />
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
};

export default FrozenTransactions;
