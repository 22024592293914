import axios from 'axios';
import { API_URL } from '../../config';

export const TRANSFER_REQUEST = 'globalcredit-smb/transfer/TRANSFER_REQUEST';
export const TRANSFER_RESPONSE = 'globalcredit-smb/transfer/TRANSFER_RESPONSE';
export const TRANSFER_ERROR = 'globalcredit-smb/transfer/TRANSFER_ERROR';

export const fetchTransferHistoryRequest = payload => ({
  type: TRANSFER_REQUEST,
  payload,
});

export const fetchTransferHistoryResponse = payload => ({
  type: TRANSFER_RESPONSE,
  payload,
});

export const fetchTransferHistoryError = payload => ({
  type: TRANSFER_ERROR,
  payload,
});

const initialState = {
  data: null,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRANSFER_RESPONSE:
      return {
        data: [...action.payload],
        loading: false,
      };
    case TRANSFER_ERROR:
    default:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
  }
}

export const getTransferHistory = () => {
  return dispatch => {
    dispatch(fetchTransferHistoryRequest());

    axios
      .get(`${API_URL}/transfers`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        return dispatch(fetchTransferHistoryResponse(data));
      })
      .catch(error => {
        return dispatch(fetchTransferHistoryError(error));
      });
  };
};
