import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Input, FormField } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { authenticate } from '../../redux/ducks/login';
import GCButton from '../../components/GCButton';
import PasswordInput from '../../components/PasswordInput';
import schema from '../../validation/schemas/loginSchema';
import icons from '../../styles/svgs';
import CopyRight from '../../components/CopyRight';
import ContactInfo from '../../components/ContactInfo';
import Validation from '../../components/Validation';
import Loader from '../Loader';
import styles from './index.module.scss';

class LoginScreen extends React.PureComponent {
  onSubmit = ({ email, password }) => {
    this.props.login(email, password);
  };

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;

    if (user) {
      const { resetToken, email } = user;

      history.push(`/reset-password/${resetToken}?email=${email}`);
    }
  }
  render() {
    const { t, loading } = this.props;

    return (
      <div id={styles.login_screen}>
        <Loader loading={loading} />
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={schema}
          onSubmit={this.onSubmit}
          render={props => {
            const { values } = props;

            return (
              <Form className="ui form">
                <div className={styles.login_container}>
                  <img src={icons.Login} alt="global-credit-logo" />
                  <a href={process.env.REACT_APP_MANUAL_URL} className={styles.manual}>
       			            {t('login.manual')}   <img src={icons.Link} alt="link"  className={styles.linkIcon}/> 
     		          </a>
                  <FormField>
                    <label htmlFor="email" className={styles.label}>
                      <span>{t('login.email')}</span>
                    </label>
                    <Validation name="email" showMessage={true}>
                      <Input
                        autoCapitalize="off"
                        value={values.email}
                        name="email"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <FormField>
                    <label htmlFor="password" className={styles.label}>
                      <span>{t('login.password')}</span>
                      <Link to="forgot-password" className={styles.forgot_password}>
                        {t('login.forgot_password')}
                      </Link>
                    </label>
                    <Validation name="password" showMessage={true}>
                      <PasswordInput
                        value={values.password}
                        name="password"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <GCButton type="submit" large>
                    {t('login.button')}
                  </GCButton>
                  <Link to="sign-up" className={styles.sign_up}>
                    {t('sign_up.title')}
                  </Link>
                </div>
              </Form>
            );
          }}
        />
        <ContactInfo />
        <CopyRight />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  loading: state.login.loading,
  isAuthenticated: state.login.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  login: (username, password) => {
    dispatch(authenticate(username, password));
  },
});

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginScreen);
