import React from 'react';
import { connect } from 'react-redux';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { BUSINESS_INFO_UPDATE_INTERVAL } from '../../constants';

import GCButton from '../GCButton';
import { getBusinessUpdatedInfo, changeModalState } from '../../redux/ducks/businessUpdatedInfo';

class BusinessUpdatedInfo extends React.PureComponent {
  componentDidMount() {
    const { get, business } = this.props;

    if (business.version) {
      get();

      this.intervalId = setInterval(get, BUSINESS_INFO_UPDATE_INTERVAL);
    }
  }

  componentWillUnmount() {
    this.intervalId && clearInterval(this.intervalId);
  }

  closeModal = () => {
    this.props.changeModalState(false);
  };

  openModal = () => {
    this.props.changeModalState(true);
  };

  updateInfo = () => {
    window.location.replace('/home');
    this.closeModal();
  };

  render() {
    const { t, modalOpen } = this.props;

    return (
      <Modal
        closeIcon
        open={modalOpen}
        onClose={this.closeModal}
        onOpen={this.openModal}
        dimmer="inverted"
        closeOnDimmerClick={false}
        className="update-modal"
      >
        <Modal.Content>
          <p>{t('business_updated_info.message')}</p>
        </Modal.Content>
        <Modal.Actions>
          <GCButton onClick={this.updateInfo}>{t('business_updated_info.refresh')}</GCButton>
          <GCButton onClick={this.closeModal} secondary>
            {t('business_updated_info.close')}
          </GCButton>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modalOpen: state.businessUpdatedInfo.isModalOpen,
  business: state.business.data,
});

const mapDispatchToProps = dispatch => ({
  get: () => dispatch(getBusinessUpdatedInfo()),
  changeModalState: state => dispatch(changeModalState(state)),
});

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BusinessUpdatedInfo);
