import axios from 'axios';

import { isAuthenticated, removeToken, setToken } from '../../helpers/auth';
import { API_URL } from '../../config';

export const LOGIN_REQUEST = 'globalcredit-smb/login/LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'globalcredit-smb/login/LOGIN_RESPONSE';
export const LOGIN_RESPONSE_FIRST_TIME = 'globalcredit-smb/login/LOGIN_RESPONSE_FIRST_TIME';
export const LOGIN_ERROR = 'globalcredit-smb/login/LOGIN_ERROR';
export const LOGOUT_REQUEST = 'globalcredit-smb/logout/LOGOUT_REQUEST';
export const LOGOUT_RESPONSE = 'globalcredit-smb/logout/LOGOUT_RESPONSE';
export const LOGOUT_ERROR = 'globalcredit-smb/logout/LOGOUT_ERROR';
export const RESET_PASSWORD_REQUEST = 'globalcredit-smb/logout/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RESPONSE = 'globalcredit-smb/logout/RESET_PASSWORD_RESPONSE';
export const RESET_PASSWORD_ERROR = 'globalcredit-smb/logout/RESET_PASSWORD_ERROR';
export const FORGOT_PASSWORD_REQUEST = 'globalcredit-smb/logout/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_RESPONSE = 'globalcredit-smb/logout/FORGOT_PASSWORD_RESPONSE';
export const FORGOT_PASSWORD_ERROR = 'globalcredit-smb/logout/FORGOT_PASSWORD_ERROR';
export const AUTH_CLEANUP = 'globalcredit-smb/auth/AUTH_CLEANUP';

export const SIGN_UP_REQUEST = 'globalcredit-smb/auth/SIGN_UP_REQUEST';
export const SIGN_UP_RESPONSE = 'globalcredit-smb/auth/SIGN_UP_RESPONSE';
export const SIGN_UP_ERROR = 'globalcredit-smb/auth/SIGN_UP_ERROR';

export const login = (email, password) => ({
  type: LOGIN_REQUEST,
  payload: { email, password },
});

export const loginFirstTime = payload => ({
  type: LOGIN_RESPONSE_FIRST_TIME,
  payload: payload,
});

export const loginFulfilled = payload => ({
  type: LOGIN_RESPONSE,
  payload,
});

export const loginFailed = payload => ({
  type: LOGIN_ERROR,
  payload: payload,
  error: true,
});

export const logoutFulfilled = payload => ({
  type: LOGOUT_RESPONSE,
  loading: false,
  payload,
});

export const logoutFailed = payload => ({
  type: LOGOUT_ERROR,
  payload: payload,
  error: true,
  loading: false,
});

export const resetPasswordRequest = payload => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordResponse = payload => ({
  type: RESET_PASSWORD_RESPONSE,
  payload,
});

export const resetPasswordError = payload => ({
  type: RESET_PASSWORD_ERROR,
  payload,
});

export const forgotPasswordRequest = payload => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordResponse = payload => ({
  type: FORGOT_PASSWORD_RESPONSE,
  payload,
});

export const forgotPasswordError = payload => ({
  type: FORGOT_PASSWORD_ERROR,
  payload,
});

export const authenticationCleanup = () => ({
  type: AUTH_CLEANUP,
});

export const signUpRequest = () => ({
  type: SIGN_UP_REQUEST,
});

export const signUpFulfilled = () => ({
  type: SIGN_UP_RESPONSE,
});

export const signUpFailed = payload => ({
  type: SIGN_UP_ERROR,
  payload,
});

const initialState = {
  isAuthenticated: isAuthenticated(),
  loading: false,
  signUp: {
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        user: null,
      };
    case LOGIN_RESPONSE_FIRST_TIME:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: false,
        loading: false,
      };
    case LOGIN_RESPONSE:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticated: true,
        error: null,
      };
    case LOGOUT_RESPONSE:
      return {
        ...state,
        isAuthenticated: false,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
      };
    case RESET_PASSWORD_ERROR:
    case FORGOT_PASSWORD_ERROR:
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_RESPONSE:
      return {
        ...state,
        loading: false,
      };
    case AUTH_CLEANUP:
      return {
        ...state,
        isAuthenticated: false,
      };

    case SIGN_UP_REQUEST:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          loading: true,
        },
      };
    case SIGN_UP_RESPONSE:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          loading: false,
          error: null,
        },
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

export const authenticate = (email, password) => {
  return dispatch => {
    dispatch(login(email, password));

    axios
      .post(`${API_URL}/auth/login`, { email, password })
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        if (data.token) {
          setToken(data);

          return dispatch(loginFulfilled(data));
        }
        return dispatch(loginFirstTime({ ...data, email }));
      })
      .catch(error => {
        return dispatch(loginFailed(error));
      });
  };
};

export const reset = data => {
  return dispatch => {
    dispatch(resetPasswordRequest());

    axios
      .post(`${API_URL}/auth/reset`, data)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;
        if (data.token) {
          setToken(data);

          return dispatch(resetPasswordResponse(data));
        }
      })
      .catch(error => {
        return dispatch(resetPasswordError(error));
      });
  };
};

export const recovery = data => {
  return dispatch => {
    dispatch(forgotPasswordRequest());

    axios
      .post(`${API_URL}/auth/recovery`, data)
      .then(r => r.data)
      .then(() => {
        return dispatch(forgotPasswordResponse());
      })
      .catch(error => dispatch(forgotPasswordError(error)));
  };
};

export const logout = () => {
  return dispatch => {
    axios
      .post(`${API_URL}/auth/logout`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        if (data && data.message) {
          removeToken();
        }
        return dispatch(logoutFulfilled(data.message));
      })
      .catch(error => {
        return dispatch(logoutFailed(error));
      });
  };
};

export const signUp = data => {
  return dispatch => {
    dispatch(signUpRequest());

    return axios
      .post(`${API_URL}/auth/register`, data)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        if (data.token) {
          setToken(data);

          dispatch(loginFulfilled(data));
        }
        dispatch(signUpFulfilled());
      })
      .catch(error => {
        dispatch(signUpFailed(error));
        throw error;
      });
  };
};

export const resend = () => {
  return axios.post(`${API_URL}/auth/send-verification-email`);
};

export const verifyUser = url => {
  return axios.get(url);
};
