import React, { Component } from 'react';
import { compose } from 'redux';
import querystring from 'query-string';
import { getTransfer } from '../../../redux/ducks/transferDetails';
import { connect } from 'react-redux';
import TransferDetailsForm from './TransferDetailsForm';
import DetailsScreen from '../DetailsScreen';

class TransferDetails extends Component {
  componentDidMount() {
    const {
      location: { search },
      getTransfer,
    } = this.props;
    const { t_id } = querystring.parse(search);

    if (t_id) {
      return getTransfer(t_id);
    }
  }

  render() {
    const { loading, transferDetails } = this.props;

    const component = () => <TransferDetailsForm transfer={transferDetails} />;

    return (
      <DetailsScreen
        component={component}
        loading={loading}
        containerLabelSize="short"
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTransfer: t_id => dispatch(getTransfer(t_id)),
  };
};

function mapStateToProps(
  { transferDetails: { data = {}, loading } },
  { location: { search } }
) {
  const { t_id } = querystring.parse(search, { parseNumbers: true });

  const transferDetails = data.id === t_id ? data : undefined;

  return {
    transferDetails,
    loading,
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TransferDetails);
