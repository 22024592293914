import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { uploadOrganizationLogo } from '../../../redux/ducks/settings';
import Toast from '../../Toast';
import Loader from '../../Loader';
import icons from '../../../styles/svgs';
import { getImagePath } from '../../../helpers/common';

import styles from './index.module.scss';

const BusinessAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name, image, contractNumber, tin } = useSelector(state => {
    return state.business.data;
  });
  const { loading } = useSelector(state => state.settings.organization);
  const { loading: businessLoading } = useSelector(state => state.business);

  const onChangeOrganizationLogo = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = async function() {
      try {
        await dispatch(uploadOrganizationLogo({ image: reader.result }));
        toast.success(<Toast text={t('settings.business_account.success_message')} />);
      } catch (e) {
        toast.error(<Toast text={t('validation_errors.general')} error />);
      }
    };
  };

  const onDeleteImg = async () => {
    try {
      await dispatch(uploadOrganizationLogo({ image: '' }));
      toast.success(<Toast text={t('settings.business_account.remove_message')} />);
    } catch (e) {
      toast.error(<Toast text={t('validation_errors.general')} error />);
    }
  };

  if (loading || businessLoading) {
    return <Loader loading />;
  }

  return (
    <div id={styles.business_account}>
      <div className={styles.header}>
        <div className={styles.avatar}>
          <label htmlFor="avatar">
            <input
              type="file"
              id="avatar"
              hidden
              accept="image/*"
              onChange={onChangeOrganizationLogo}
              onClick={event => (event.target.value = '')}
            />
            <Image
              src={image ? getImagePath(image) : icons.OrganizationDefaultLogo}
              className={styles.image}
            />
          </label>
          {image && <Image src={icons.Close} className={styles.close_icon} onClick={onDeleteImg} />}
        </div>
        <div className={styles.account_name}>
          <p>{name}</p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.content_item}>
          <p>{t('settings.business_account.contract_number')}</p>
          <p>{contractNumber}</p>
        </div>
        <div className={styles.content_item}>
          <p>{t('settings.business_account.tin')}</p>
          <p>{tin}</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessAccount;
