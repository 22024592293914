import Login from './login.svg';
import Credit_Card from './credit_card.svg';
import Calendar from './calendar.svg';
import Calendar2 from './calendar_2.svg';
import Avatar from './avatar.svg';
import Menu from './menu_icons/menu_icon.svg';
import SignOut from './menu_icons/sign_out.svg';
import Dashboard from './menu_icons/dashboard_icon.svg';
import Notification from './menu_icons/notification.svg';
import Bell from './bell.svg';
import Time from './time.svg';
import LongModalBottomRectangle from './long_modal_bottom_rectangle.svg';
import ShortModalBottomRectangle from './short_modal_bottom_rectangle.svg';
import Settings from './menu_icons/setting.svg';
import Success from './success.svg';
import Success2 from './success2.svg';
import Error from './error.svg';
import Error2 from './error2.svg';
import HistoryArrow from './historyArrow.svg';
import CurrencyMenuItem from './currencyMenuItem.svg';
import VendorDefault from './vendor_default.svg';
import ChangePassword from './change_password.svg';
import Lock from './lock.svg';
import AccountSetting from './account-setting.svg';
import EInvoicing from './e_invoicing.svg';
import EInvoicing2 from './e_invoicing_2.svg';
import TaxService from './tax_service.png';
import Background from './background.svg';
import OrganizationDefaultLogo from './organization-default-logo.svg';
import BusinessAccountSetting from './business-account-setting.svg';
import Close from './close.svg';
import Deposit from './deposit.svg';
import Freeze from './freeze.svg';
import Unfreeze from './unfreeze.svg';
import Arrow from './arrow.svg';
import List from './list.svg';
import ArmFlag from './arm_flag.svg';
import Email from './email.svg';
import Assurance from './assurance.svg';
import Link from './link.svg';

export default {
  Login,
  Link,
  Credit_Card,
  Calendar,
  Calendar2,
  Avatar,
  Menu,
  SignOut,
  Dashboard,
  Success,
  Success2,
  Error,
  Error2,
  CurrencyMenuItem,
  HistoryArrow,
  VendorDefault,
  Notification,
  Bell,
  Time,
  LongModalBottomRectangle,
  ShortModalBottomRectangle,
  Settings,
  ChangePassword,
  Lock,
  AccountSetting,
  EInvoicing,
  EInvoicing2,
  TaxService,
  Background,
  OrganizationDefaultLogo,
  BusinessAccountSetting,
  Close,
  Deposit,
  Freeze,
  Unfreeze,
  Arrow,
  List,
  ArmFlag,
  Email,
  Assurance,
};
