import * as Yup from 'yup';
import i18n from '../../i18n';
import './validators';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t('validator.reset_password.password_required'))
    .password(),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      i18n.t('validator.reset_password.password_equal')
    )
    .required(i18n.t('validator.reset_password.password_required')),
});

export default resetPasswordSchema;
