import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Modal } from 'semantic-ui-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import hy from 'date-fns/locale/hy';
import InputMask from 'react-input-mask';
import styles from './index.module.scss';

registerLocale('hy', hy);

const FilterModal = ({ startDate, endDate, onClose, onSave }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState({ from: startDate, to: endDate });

  const onChangeDatePicker = (d, key) => {
    setDate({ ...date, [key]: d });
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      dimmer="inverted"
      className={styles.e_invoice_date_modal}
      size="tiny"
    >
      <div className={styles.header}>
        <p>{t('e_invoicing.filter_title')}</p>
        <Icon name="x" onClick={onClose} />
      </div>
      <div className={styles.content}>
        <div className={styles.content_item}>
          <p>{t('e_invoicing.start_date')}</p>
          <div className={styles.date_dicker_wrapper}>
            <DatePicker
              selected={date.from}
              onChange={date => onChangeDatePicker(date, 'from')}
              locale="hy"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              customInput={<InputMask mask="99/99/9999" />}
            />
          </div>
        </div>
        <div className={styles.content_item}>
          <p>{t('e_invoicing.end_date')}</p>
          <div className={styles.date_dicker_wrapper}>
            <DatePicker
              selected={date.to}
              onChange={date => onChangeDatePicker(date, 'to')}
              locale="hy"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              customInput={<InputMask mask="99/99/9999" />}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <button
          type="submit"
          className={styles.save_button}
          onClick={() => onSave(date)}
        >
          {t('e_invoicing.apply')}
        </button>
        <button
          type="button"
          className={styles.cancel_button}
          onClick={onClose}
        >
          {t('e_invoicing.cancel')}
        </button>
      </div>
    </Modal>
  );
};

export default FilterModal;
