import React from 'react';
import classNames from 'classnames';
import { List } from 'semantic-ui-react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Currency from '../../../Currency';
import { DATE_FORMAT } from '../../../../constants';
import { formatLocalDate } from '../../../../helpers/common';

import styles from '../../HistoryScreen/index.module.scss';

class PaymentItem extends React.PureComponent {
  render() {
    const { item, t } = this.props;

    return (
      <React.Fragment>
        <List.Header className={styles.list_header}>
          {formatLocalDate(item.date, DATE_FORMAT)}
        </List.Header>
        <List.Content className={styles.content}>
          <List.Description className={styles.item_description}>
            <div className={styles.row}>
              <div className={classNames(styles.info, styles.left_container)}>
                <div className={styles.label}>{t('payment-history.payment-method')}</div>
                <div className={styles.value}>{t(`payment-type.${item.type}`)}</div>
              </div>
              <div className={styles.info}>
                <div className={styles.label}>{t('payment-history.amount')}</div>
                <div className={classNames(styles.value, styles.amount)}>
                  <Currency value={item.totalAmount} hasSign />
                </div>
              </div>
            </div>
          </List.Description>
        </List.Content>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation('translations'))(PaymentItem);
