import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { closeModal } from '../../redux/ducks/modal';

import styles from './index.module.scss';

class ModalContainer extends Component {
  render() {
    const { isOpen, content, params, closeModal } = this.props;

    if (!isOpen || typeof content !== 'function') {
      return null;
    }

    return (
      <div id={styles.fullscreen_inline_modal}>
        <div className={styles.modal_header}>
          <div onClick={closeModal} className={styles.arrow_icon}>
            <Icon name="arrow left" />
          </div>
          {params.title}
        </div>

        <div>{content()}</div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal()),
  };
};

function mapStateToProps({ modal: { isOpen, content, params } }) {
  return {
    isOpen,
    content,
    params,
  };
}

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ModalContainer);
