import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import LoanDetailsForm from './LoanDetailsForm';
import DetailsScreen from '../DetailsScreen';

class LoanDetails extends Component {
  render() {
    const { loading, loanDetails } = this.props;

    const component = () => <LoanDetailsForm details={loanDetails} />;

    return <DetailsScreen component={component} loading={loading} containerLabelSize="long" />;
  }
}
const mapStateToProps = state => ({
  loanDetails: state.business.data.loan,
  loading: state.business.loading,
});

export default compose(connect(mapStateToProps))(LoanDetails);
