import { Button } from 'semantic-ui-react';
import React from 'react';
import classnames from 'classnames';

import './index.module.scss';

const GCButton = ({
  className,
  color,
  primary,
  secondary,
  action,
  medium,
  large,
  small,
  ...props
}) => {
  const type =
    (action && 'gc_action') || (secondary && 'gc_secondary') || 'gc_primary';

  const customSize =
    (large && 'gc_large') || (medium && 'gc_medium') || (small && 'gc_small');

  return (
    <Button
      {...props}
      className={classnames('gc_default', className, type, customSize)}
    />
  );
};

export default GCButton;
