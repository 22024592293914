import React from 'react';
import { Dimmer, Loader as SemanticUiLoader } from 'semantic-ui-react';

const Loader = ({ loading, page }) => (
  <Dimmer inverted active={loading} page={page}>
    <SemanticUiLoader />
  </Dimmer>
);

export default Loader;
