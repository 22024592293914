import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import AuthenticationRoute from './containers/AuthenticationRoute';
import SiteWrapper from './containers/SiteWrapper';
import ModalContainer from './containers/ModalContainer';
import LoginScreen from './components/LoginScreen';
import SignUpScreen from './components/SignUpScreen';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import EmailVerifyScreen from './components/EmailVerifyScreen';

import routes from './helpers/routes';

function App({ isModalOpen, ...props }) {
  const routeConfig = routes(props);

  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar position="top-center" closeButton={false} />
      <ModalContainer />
      <div style={{ display: isModalOpen ? 'none' : '' }}>
        <Switch>
          <AuthenticationRoute
            path="/login"
            withAuth={false}
            component={LoginScreen}
            redirectOnFailure="/home"
          />
          <AuthenticationRoute
            path="/sign-up"
            withAuth={false}
            component={SignUpScreen}
            redirectOnFailure="/home"
          />
          <AuthenticationRoute
            path="/forgot-password"
            component={ForgotPassword}
            redirectOnFailure="/home"
          />
          <AuthenticationRoute
            path="/reset-password"
            component={ResetPassword}
            redirectOnFailure="/home"
          />
          <AuthenticationRoute
            path="/email/verify"
            component={EmailVerifyScreen}
            redirectOnFailure="/home"
          />
          <AuthenticationRoute
            path="/"
            withAuth={true}
            redirectOnFailure="/login"
            render={() => (
              <SiteWrapper routes={routeConfig}>
                <Switch>
                  {routeConfig.map((route, index) => (
                    <Route key={index} path={route.path} component={route.component} />
                  ))}
                  <Redirect to="/home" />
                </Switch>
              </SiteWrapper>
            )}
          />
        </Switch>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  transferHistory: state.transferHistory.data,
  paymentHistory: state.paymentHistory.data,
  isModalOpen: state.modal.isOpen,
  transferDetails: state.transferDetails.data,
  notifications: state.notifications.readNotifications,
  business: state.business.data,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(App);
