import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import Loader from '../../Loader';
import ScrollManager from '../../ScrollManager';

import styles from './index.module.scss';

class HistoryScreen extends React.PureComponent {
  render() {
    const { link, loading, component, isLink = true } = this.props;
    const historyList = this.props.historyList || [];

    return (
      <div id={styles.history_list}>
        <ScrollManager scrollKey="history-list" />
        <Loader loading={loading} />
        <List>
          {historyList.map((history, index) => {
            if (!isLink) {
              return (
                <List.Item className={styles.item} key={index}>
                  {component(history)}
                </List.Item>
              );
            }

            return (
              <List.Item
                as={Link}
                key={index}
                to={`/${link}/${history.id}`}
                className={styles.item}
              >
                {component(history)}
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default HistoryScreen;
