import axios from 'axios';
import { API_URL } from '../../config';

export const PROFILE_FETCH = 'globalcredit-smb/profile/PROFILE_REQUEST';
export const PROFILE_FETCHED = 'globalcredit-smb/profile/PROFILE_RESPONSE';
export const PROFILE_ERROR = 'globalcredit-smb/profile/PROFILE_ERROR';

export const fetchProfile = payload => ({
  type: PROFILE_FETCH,
  payload,
});

export const fetchProfileFullFilled = payload => ({
  type: PROFILE_FETCHED,
  payload,
});

export const fetchProfileError = payload => ({
  type: PROFILE_ERROR,
  payload,
});

const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROFILE_FETCHED:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export const getUserProfile = () => {
  return dispatch => {
    dispatch(fetchProfile());

    return axios
      .get(`${API_URL}/me`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        dispatch(fetchProfileFullFilled(data));
        return data;
      })
      .catch(error => {
        return fetchProfileError(error);
      });
  };
};
