/**
 * We use 'Ducks' proposal for combining reducers,
 * actions, action creators and epics in one file
 *
 * For more information:
 * https://github.com/erikras/ducks-modular-redux
 */
import React from 'react';
import { combineReducers } from 'redux';
import axios from 'axios';
import humps from 'humps';
import { toast } from 'react-toastify';

import login, { LOGOUT_RESPONSE } from './login';
import vendor from './vendor';
import business from './business';
import businessUpdatedInfo from './businessUpdatedInfo';
import feeAmount from './feeAmount';
import supplier from './supplier';
import userProfile from './userProfile';
import transferDetails from './transferDetails';
import transferHistory from './transferHistory';
import paymentHistory from './paymentHistory';
import notifications from './notifications';
import settings from './settings';
import EInvoices from './EInvoices';
import modal from './modal';
import frozenTransactions from './frozenTransactions';
import ServerError from '../../components/Validation/ServerError';

axios.interceptors.request.use(
  config => {
    return {
      ...config,
      data: humps.decamelizeKeys(config.data),
    };
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.data.success) {
      return {
        ...response,
        data: humps.camelizeKeys(response.data),
      };
    }
    return response;
  },
  error => {
    const errorData = error.response.data.error;
    if (errorData.code) {
      toast.error(<ServerError error={errorData} />, {
        toastId: errorData.code,
      });
    }

    return Promise.reject(error);
  }
);

const appReducer = combineReducers({
  login,
  vendor,
  business,
  supplier,
  userProfile,
  transferDetails,
  transferHistory,
  paymentHistory,
  modal,
  notifications,
  settings,
  businessUpdatedInfo,
  EInvoices,
  frozenTransactions,
  feeAmount,
});

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_RESPONSE) {
    state = undefined;
  }

  return appReducer(state, action);
};
