import React from 'react';
import { withTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';

import Currency from '../Currency';
import speedometerPainter from './speedometerPainter';
import { numberToDecimalString } from '../../helpers/numberHelpers';

import styles from './index.module.scss';

const Speedometer = ({ loanLimit, availableBalance, unavailableBalance, t }) => {
  const percent = (availableBalance * 100) / loanLimit;
  const minPercent = (unavailableBalance * 100) / loanLimit;

  return (
    <div className={styles.speedometer}>
      <div className={styles.speedometer_container}>
        {speedometerPainter({ percent, minPercent })}
        <div className={styles.balance}>
          <div className={styles.balance_container}>
            <Currency value={availableBalance} hasSign precision={0} />
          </div>
          <div className={styles.title}> {t('speedometer.title')}</div>
          <div className={styles.info_container}>
            {unavailableBalance ? (
              <>
                <Label circular className={styles.label} empty size="mini" />
                <div className={styles.info}>
                  {t('speedometer.info', {
                    amount: numberToDecimalString(unavailableBalance),
                  })}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(Speedometer);
