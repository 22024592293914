import * as Yup from 'yup';
import i18n from '../../i18n';

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('validator.login.email_invalid'))
    .required(i18n.t('validator.login.email_required')),
});

export default forgotPasswordSchema;
