import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Input, FormField, Image } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { setEInvoicingData } from '../../../redux/ducks/settings';
import GCButton from '../../GCButton';
import PasswordInput from '../../PasswordInput';
import schema from '../../../validation/schemas/EInvoicingSchema';
import Validation from '../../Validation';
import Toast from '../../Toast';
import Loader from '../../Loader';
import icons from '../../../styles/svgs';

import styles from './index.module.scss';
import querystring from 'query-string';

class EInvoicing extends React.PureComponent {
  state = {
    passwordDisabled: false,
    passwordDisabledChanged: false,
  };

  onSubmit = payload => {
    this.props.setEInvoicingData(payload);
  };

  onFocusPasswordField = () => {
    const { passwordDisabled } = this.state;
    if (passwordDisabled) {
      this.setState({ passwordDisabled: false });
    }
  };

  componentDidMount() {
    const { passwordDisabledChanged } = this.state;
    const {
      t,
      location: { search },
      data: { eInvoicingUsername },
    } = this.props;

    const { notify } = querystring.parse(search, { parseBooleans: true });
    if (notify && !eInvoicingUsername) {
      toast.error(<Toast text={t('settings.e_invoicing.missing')} error />);
    }

    if (eInvoicingUsername && !passwordDisabledChanged) {
      this.setState({ passwordDisabled: true, passwordDisabledChanged: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { passwordDisabledChanged } = this.state;
    const {
      t,
      loading,
      error,
      history,
      data: { eInvoicingUsername },
    } = this.props;

    if (prevProps.loading && !loading && !error) {
      toast.success(<Toast text={t('settings.e_invoicing.success_message')} />);
      history.push('/home');
    }

    if (eInvoicingUsername && !passwordDisabledChanged) {
      this.setState({ passwordDisabled: true, passwordDisabledChanged: true });
    }
  }

  render() {
    const { passwordDisabled } = this.state;
    const {
      t,
      loading,
      history,
      data: { eInvoicingUsername = '' },
    } = this.props;

    return (
      <div id={styles.e_invoicing}>
        <Loader loading={loading} />
        <Formik
          enableReinitialize
          initialValues={{
            eInvoicingUsername: eInvoicingUsername ?? '',
            eInvoicingPassword: '',
          }}
          validationSchema={schema}
          onSubmit={this.onSubmit}
          render={props => {
            const { values } = props;

            return (
              <Form className="ui form">
                <div>
                  <Image src={icons.TaxService} centered className={styles.img} />
                  <div className={styles.card}>
                    <p className={styles.title}>{t('settings.e_invoicing.title')}</p>
                    <FormField>
                      <label htmlFor="eInvoicingUsername" className={styles.label}>
                        <span>{t('settings.e_invoicing.username')}</span>
                      </label>
                      <Validation name="eInvoicingUsername" showMessage={true}>
                        <Input
                          autoCapitalize="off"
                          value={values.eInvoicingUsername}
                          name="eInvoicingUsername"
                          className={styles.login_input}
                          onFocus={this.onFocusPasswordField}
                        />
                      </Validation>
                    </FormField>
                    <FormField>
                      <label htmlFor="eInvoicingPassword" className={styles.label}>
                        <span>{t('settings.e_invoicing.password')}</span>
                      </label>
                      <Validation name="eInvoicingPassword" showMessage={true}>
                        <PasswordInput
                          value={values.eInvoicingPassword}
                          name="eInvoicingPassword"
                          className={classnames(styles.login_input, {
                            [styles.password_disabled]: passwordDisabled,
                          })}
                          autoComplete="new-password"
                          lockIcon={passwordDisabled}
                          placeholder={passwordDisabled ? '••••••' : ''}
                          onFocus={this.onFocusPasswordField}
                        />
                      </Validation>
                    </FormField>
                    <GCButton
                      type="submit"
                      large
                      className={styles.save_button}
                      onFocus={this.onFocusPasswordField}
                    >
                      {t('settings.e_invoicing.save_button')}
                    </GCButton>
                    <GCButton
                      type="button"
                      secondary
                      large
                      className={styles.cancel_button}
                      onClick={() => history.push('/home')}
                    >
                      {t('settings.e_invoicing.cancel_button')}
                    </GCButton>
                    <div className={styles.privacy_assurance}>
                      <Image src={icons.Assurance} className={styles.icon} />
                      <span className={styles.message}>
                        {t('settings.e_invoicing.privacy_assurance_message')}
                      </span>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.business.data,
  loading: state.settings.eInvoicing.loading,
  error: state.settings.eInvoicing.error,
});

const mapDispatchToProps = dispatch => ({
  setEInvoicingData: data => dispatch(setEInvoicingData(data)),
});

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EInvoicing);
