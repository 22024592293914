import axios from 'axios';
import querystring from 'query-string';
import _filter from 'lodash/filter';
import { API_URL } from '../../config';
import { getMyBusiness } from './business';

export const CONFIRM_TRANSFER_REQUEST = 'globalcredit-smb/new-transfer/CONFIRM_TRANSFER_REQUEST';
export const CONFIRM_TRANSFER_RESPONSE = 'globalcredit-smb/new-transfer/CONFIRM_TRANSFER_RESPONSE';
export const CONFIRM_TRANSFER_ERROR = 'globalcredit-smb/new-transfer/CONFIRM_TRANSFER_ERROR';
export const GET_TRANSFER_REQUEST = 'globalcredit-smb/new-transfer/GET_TRANSFER_REQUEST';
export const GET_TRANSFER_RESPONSE = 'globalcredit-smb/new-transfer/GET_TRANSFER_RESPONSE';
export const GET_TRANSFER_ERROR = 'globalcredit-smb/new-transfer/GET_TRANSFER_ERROR';

export const GET_CURRENT_INVOICE_REQUEST =
  'globalcredit-smb/new-transfer/GET_CURRENT_INVOICE_REQUEST';
export const GET_CURRENT_INVOICE_RESPONSE =
  'globalcredit-smb/new-transfer/GET_CURRENT_INVOICE_RESPONSE';
export const GET_CURRENT_INVOICE_ERROR = 'globalcredit-smb/new-transfer/GET_CURRENT_INVOICE_ERROR';
export const SET_CURRENT_INVOICE = 'globalcredit-smb/new-transfer/SET_CURRENT_INVOICE';

export const getTransferRequest = payload => ({
  type: GET_TRANSFER_REQUEST,
  payload,
});

export const getTransferResponse = payload => {
  return {
    type: GET_TRANSFER_RESPONSE,
    payload,
  };
};

export const getTransferError = payload => ({
  type: GET_TRANSFER_ERROR,
  payload,
});

export const confirmTransferRequest = () => ({
  type: CONFIRM_TRANSFER_REQUEST,
});

export const confirmTransferResponse = payload => {
  return {
    type: CONFIRM_TRANSFER_RESPONSE,
    payload,
  };
};

export const confirmTransferError = payload => ({
  type: CONFIRM_TRANSFER_ERROR,
  payload,
});

export const fetchCurrentInvoice = () => ({
  type: GET_CURRENT_INVOICE_REQUEST,
});

export const fetchCurrentInvoiceFulfilled = payload => ({
  type: GET_CURRENT_INVOICE_RESPONSE,
  payload,
});

export const fetchCurrentInvoiceFailed = () => ({
  type: GET_CURRENT_INVOICE_ERROR,
});

export const setInvoice = payload => ({
  type: SET_CURRENT_INVOICE,
  payload,
});

const initialState = {
  data: undefined,
  transfer: undefined,
  loading: false,
  invoice: {},
  invoiceLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSFER_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_TRANSFER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONFIRM_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONFIRM_TRANSFER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONFIRM_TRANSFER_RESPONSE: {
      return {
        ...state,
        loading: false,
        transfer: action.payload,
      };
    }
    case GET_CURRENT_INVOICE_REQUEST: {
      return {
        ...state,
        invoice: {},
        invoiceLoading: true,
      };
    }
    case GET_CURRENT_INVOICE_RESPONSE: {
      return {
        ...state,
        invoice: action.payload,
        invoiceLoading: false,
      };
    }
    case GET_CURRENT_INVOICE_ERROR: {
      return {
        ...state,
        invoiceLoading: false,
      };
    }
    case SET_CURRENT_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };

    default:
      return state;
  }
}

export const confirmTransfer = data => {
  return dispatch => {
    dispatch(confirmTransferRequest());
    return axios
      .post(`${API_URL}/transfers`, data)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        dispatch(getMyBusiness());

        dispatch(confirmTransferResponse(data));
      })
      .catch(error => {
        dispatch(confirmTransferError(error));
        throw Error();
      });
  };
};

export const confirmUnfreezeTransfer = (id, data) => {
  return dispatch => {
    dispatch(confirmTransferRequest());

    return axios
      .patch(`${API_URL}/transfers/${id}`, data)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        dispatch(getMyBusiness());
        dispatch(confirmTransferResponse(data));
      })
      .catch(error => {
        dispatch(confirmTransferError(error));
        throw Error();
      });
  };
};

export const getTransfer = transferId => {
  return dispatch => {
    dispatch(getTransferRequest());

    return axios
      .get(`${API_URL}/transfers/${transferId}`)
      .then(r => r.data)
      .then(resp => {
        let { data } = resp;

        dispatch(getTransferResponse(data));
        return data;
      })
      .catch(error => {
        return dispatch(getTransferError(error));
      });
  };
};

export const getCurrentInvoice = (
  { vendorId, invoiceNumber, supplyStartDate, supplyEndDate },
  handleMatching
) => {
  return (dispatch, getState) => {
    dispatch(fetchCurrentInvoice());

    const queryParams = querystring.stringify({
      vendor_id: vendorId,
      invoice_number: invoiceNumber,
      supply_start_date: supplyStartDate,
      supply_end_date: supplyEndDate,
    });

    return axios
      .get(`${API_URL}/invoices?${queryParams}`)
      .then(r => r.data)
      .then(resp => {
        const { data } = resp;

        if (handleMatching) {
          const {
            transferDetails: { data: transfer = {} },
          } = getState();
          const matchingList = _filter(data, item => item.amount === transfer.amount);

          if (matchingList.length === 1) {
            dispatch(fetchCurrentInvoiceFulfilled(matchingList[0]));
          } else {
            dispatch(fetchCurrentInvoiceFailed());
          }
        } else {
          const [invoice = { invoiceNumber }] = data;
          dispatch(fetchCurrentInvoiceFulfilled(invoice));
        }
        return data;
      })
      .catch(error => {
        dispatch(fetchCurrentInvoiceFailed());
      });
  };
};
