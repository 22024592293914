import React from 'react';
import FormatNumber from 'react-number-format';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './index.module.scss';

const NumberInput = ({ inputInfo, onChange, className, amountWarning, popupContent, ...props }) => (
  <div className={classNames(className, 'ui input')}>
    <FormatNumber
      type="tel"
      thousandSeparator={true}
      allowNegative={false}
      onChange={args => {
        const value = args.target.value;
        const amount = value.replace(/,/g, '');

        if (amount) {
          return onChange(parseInt(amount));
        }

        onChange('');
      }}
      {...props}
    />
    {!amountWarning && inputInfo && (
      <label htmlFor="amount" className={styles.currency}>
        {inputInfo}
      </label>
    )}
    {amountWarning && (
      <div className={styles.warning_icon_block}>
        <div className={styles.warning_icon}>
          <Icon name="warning sign" />
          <div className={styles.popover}>{popupContent}</div>
        </div>
      </div>
    )}
  </div>
);

export default NumberInput;
