import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { Label } from 'semantic-ui-react';
import Currency from '../../../Currency';
import VendorImage from '../../../VendorImage';
import GCButton from '../../../GCButton';
import { formatLocalDate } from '../../../../helpers/common';
import { DATE_TIME_FORMAT, DATE_FORMAT, LONG_DASH } from '../../../../constants';
import { TRANSFER_TYPES_TEXT, TRANSFER_STATES_TEXT } from '../../../../constants/transferStates';

import styles from '../../DetailsScreen/index.module.scss';

class TransferDetailsForm extends React.PureComponent {
  render() {
    const {
      transfer: {
        user = { firstName: '', lastName: '' },
        vendor = { name: '', image: '' },
        supplier = { firstName: '', lastName: '' },
        amount = 0,
        feeAmount,
        invoiceNumber,
        supplyDate,
        notes = '',
        date,
        id,
        transferNumber,
        type,
        state,
        showInvoiceNumber = true,
      } = {},
      onSubmit = null,
      t,
      history,
    } = this.props;

    const fullName = (user = {}) => {
      return `${user.firstName} ${user.lastName}`.trim();
    };

    return (
      <React.Fragment>
        <span className={styles.image_wrapper}>
          <VendorImage src={vendor.image} className={styles.avatar_image} />
        </span>
        <div className={styles.container}>
          {transferNumber && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.transfer_id')}
              </Label>
              <div className={styles.details_col}>{transferNumber}</div>
            </div>
          )}
          {showInvoiceNumber && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer-history.invoice_number')}
              </Label>
              <div className={styles.details_col}>{invoiceNumber || LONG_DASH}</div>
            </div>
          )}
          {id && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.date')}
              </Label>
              <div className={styles.details_col}>{formatLocalDate(date, DATE_TIME_FORMAT)}</div>
            </div>
          )}
          {fullName(user) && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.cashier')}
              </Label>
              <div className={styles.details_col}>{fullName(user)}</div>
            </div>
          )}
          {vendor.name && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.vendor')}
              </Label>
              <div className={styles.details_col}>{`${vendor.name}`}</div>
            </div>
          )}
          {fullName(supplier) && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.supplier')}
              </Label>
              <div className={styles.details_col}>{fullName(supplier)}</div>
            </div>
          )}
          {amount ? (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.amount')}
              </Label>
              <div className={styles.details_col}>
                <Currency value={amount} hasSign />
              </div>
            </div>
          ) : (
            ''
          )}
          {feeAmount ? (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.fee')}
              </Label>
              <div className={styles.details_col}>
                <Currency value={feeAmount} hasSign />
              </div>
            </div>
          ) : (
            ''
          )}
          {feeAmount && amount ? (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.total')}
              </Label>
              <div className={styles.details_col}>
                <Currency value={feeAmount + amount} hasSign />
              </div>
            </div>
          ) : (
            ''
          )}
          {!!supplyDate && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.supplyDate')}
              </Label>
              <div className={styles.details_col}>{formatLocalDate(supplyDate, DATE_FORMAT)}</div>
            </div>
          )}
          {!!type && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer-history.type')}
              </Label>
              <div className={styles.details_col}>{TRANSFER_TYPES_TEXT[type]}</div>
            </div>
          )}
          {!!state && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer-history.state')}
              </Label>
              <div className={styles.details_col}>{TRANSFER_STATES_TEXT[state]}</div>
            </div>
          )}
          {notes && (
            <div className={styles.details_row}>
              <Label className={classnames(styles.details_col, styles.details_col_label)}>
                {t('transfer_details.notes')}
              </Label>
              <div className={styles.details_col}>{notes}</div>
            </div>
          )}
        </div>
        {onSubmit && (
          <div className={styles.row}>
            <GCButton type="submit" action className={styles.centered_button} onClick={onSubmit}>
              {t('transfer_details.submit')}
            </GCButton>
            <GCButton
              secondary
              className={styles.centered_button}
              onClick={() => {
                history.push('/home');
              }}
            >
              {t('transfer_details.cancel')}
            </GCButton>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  withTranslation('translations'),
  withRouter
)(TransferDetailsForm);
