import { useState, useEffect } from 'react';
import { MOBILE_MAX_SIZE, TABLET_MAX_SIZE, LARGE_TABLET_MAX_SIZE } from '../constants';

export const MediaQueries = {
  mobile: `only screen and (max-width: ${MOBILE_MAX_SIZE}px)`,
  tablet: `only screen and (max-width: ${TABLET_MAX_SIZE}px)`,
  largeTablet: `only screen and (max-width: ${LARGE_TABLET_MAX_SIZE}px)`,
  desktop: `only screen and (min-width : ${LARGE_TABLET_MAX_SIZE + 1}px)`,
};

export const useDeviceResize = query => {
  const queryToMatch = MediaQueries[query] || query;
  const [matches, setMatches] = useState(window.matchMedia(queryToMatch).matches);

  useEffect(() => {
    if (typeof window === 'object' && window.matchMedia) {
      const media = window.matchMedia(queryToMatch);

      if (media.matches !== matches) setMatches(media.matches);

      const listener = () => setMatches(media.matches);

      if (media.addEventListener) {
        media.addEventListener('change', listener);
      } else media.addListener(listener);

      return () =>
        media.removeEventListener
          ? media.removeEventListener('change', listener)
          : media.removeListener(listener);
    }
  }, [matches, queryToMatch]);

  return matches;
};
