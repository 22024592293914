import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import VendorImage from '../../VendorImage';
import styles from './index.module.scss';

class VendorItem extends Component {
  render() {
    const { image, length, name, t } = this.props;

    return (
      <div className={styles.vendor_item_container}>
        <VendorImage src={image} className={styles.vendor_logo} />
        <div>
          <p className={styles.vendor_name}>{name}</p>
          <p className={styles.vendor_suppliers}>
            {t('vendor.suppliers')} ({length})
          </p>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation('translations'))(VendorItem);
