import React, { Component } from 'react';
import { Card, Image } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { compose } from 'redux';
import icons from '../../styles/svgs';
import Currency from '../Currency';
import Loader from '../Loader';
import { PRECISION } from '../../constants';

import styles from './index.module.scss';

class BusinessInfo extends Component {
  cardContent = () => {
    const { t, nextPaymentDate, loading } = this.props;
    return (
      <Card.Content className={styles.link_content}>
        <Image src={icons.Calendar} className={styles.card_icons} />
        <Card.Header className={styles.card_header}>{t('business_info.next_payment')}</Card.Header>
        <Card.Description className={styles.payment_date}>
          <Loader loading={loading} />
          {nextPaymentDate && moment(nextPaymentDate).format('D ')}
          {nextPaymentDate && moment(nextPaymentDate).format('MMMM, YYYY')}
          {!nextPaymentDate && t('business_info.miss_next_payment')}
        </Card.Description>
      </Card.Content>
    );
  };

  render() {
    const {
      t,
      loanLimit = 0,
      loading,
      balanceUsage = 0,
      reservedAmount = 0,
      hasBusiness,
    } = this.props;

    return (
      <Card.Group id={styles.cards_container}>
        <Card className={styles.single_card}>
          <Card.Content className={styles.first_card}>
            <Loader loading={loading} />
            <div className={styles.first_card_content}>
              <Image src={icons.Credit_Card} className={styles.card_icons} floated="left" />
              <div className={styles.card_row}>
                <Card.Header className={styles.card_header}>
                  {t('business_info.loan_limit')}
                </Card.Header>
                <Card.Description className={styles.loan_limit}>
                  <Currency hasSign={true} value={loanLimit} precision={PRECISION} />
                </Card.Description>
              </div>
              <div className={styles.card_row}>
                <Card.Header className={styles.card_header}>
                  {t('business_info.balance_usage')}
                </Card.Header>
                <Card.Description className={styles.loan_limit}>
                  <Currency hasSign={true} value={balanceUsage} precision={PRECISION} />
                </Card.Description>
              </div>
              <div className={styles.card_row}>
                <Card.Header className={styles.card_header}>
                  {t('business_info.frozen_usage')}
                </Card.Header>
                <Card.Description className={styles.loan_limit}>
                  <Currency hasSign={true} value={reservedAmount} precision={PRECISION} />
                </Card.Description>
              </div>
            </div>
          </Card.Content>
        </Card>
        <Card className={styles.single_card}>
          {hasBusiness ? (
            <Link to="/loan-details" className={styles.link}>
              {this.cardContent()}
              <Image src={icons.HistoryArrow} className={styles.img} />
            </Link>
          ) : (
            <>{this.cardContent()}</>
          )}
        </Card>
      </Card.Group>
    );
  }
}

export default compose(withTranslation('translations'))(BusinessInfo);
