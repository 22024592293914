import axios from 'axios';
import { API_URL } from '../../config';

export const BUSINESS_INFO_REQUEST =
  'globalcredit-smb/business_info/BUSINESS_INFO_REQUEST';
export const BUSINESS_INFO_RESPONSE =
  'globalcredit-smb/business_info/BUSINESS_INFO_RESPONSE';
export const BUSINESS_INFO_ERROR =
  'globalcredit-smb/business_info/BUSINESS_INFO_ERROR';

export const fetchBusinessInfo = payload => ({
  type: BUSINESS_INFO_REQUEST,
  payload,
});

export const businessInfoFulfilled = payload => ({
  type: BUSINESS_INFO_RESPONSE,
  payload,
});

export const businessInfoFailed = payload => ({
  type: BUSINESS_INFO_ERROR,
  payload,
});

const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case BUSINESS_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BUSINESS_INFO_RESPONSE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case BUSINESS_INFO_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export const getMyBusiness = () => {
  return dispatch => {
    dispatch(fetchBusinessInfo());

    axios
      .get(`${API_URL}/business`)
      .then(r => r.data)
      .then(response => {
        const { data } = response;

        return dispatch(businessInfoFulfilled(data));
      })
      .catch(error => {
        return dispatch(businessInfoFailed(error));
      });
  };
};
