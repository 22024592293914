import React from 'react';
import Loader from '../../Loader';

import styles from './index.module.scss';

const resolveClassName = containerLabelSize => {
  switch (containerLabelSize) {
    case 'short':
      return 'short_label_container';
    case 'medium':
      return 'medium_label_container';
    default:
      return 'long_label_container';
  }
};

class DetailsScreen extends React.PureComponent {
  render() {
    const { loading, component, containerLabelSize } = this.props;

    return (
      <div id={styles.details_container}>
        <Loader loading={loading} />
        <div className={styles[resolveClassName(containerLabelSize)]}>
          {component()}
        </div>
      </div>
    );
  }
}

export default DetailsScreen;
