import React from 'react';
import moment from 'moment';
import querystring from 'query-string';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Image, List, Search } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { getSuppliers } from '../../redux/ducks/supplier';
import { getVendors } from '../../redux/ducks/vendor';
import SupplierIcon from '../../styles/svgs/new-transaction.svg';
import { DATE_TIME_FORMAT } from '../../constants';
import { vendorSelector } from '../../redux/selectors';
import { formatLocalDate } from '../../helpers/common';

import Toast from '../Toast';
import Loader from '../Loader';
import Avatar from '../Avatar';
import styles from './index.module.scss';

class SuppliersScreen extends React.PureComponent {
  componentDidMount() {
    const {
      match: { params },
      vendors,
    } = this.props;

    this.props.fetchSuppliers(params.v_id);

    if (!vendors.length) {
      this.props.fetchVendors();
    }
  }

  state = { criteria: '' };

  componentDidUpdate(prevProps) {
    const { t, suppliers, history } = this.props;

    if (prevProps.suppliers !== suppliers && !suppliers.length) {
      toast.error(<Toast text={t('suppliers.empty')} error />);
      history.push('/home');
    }
  }

  filterSuppliers = () => {
    const { suppliers } = this.props;

    return _.filter(suppliers, result => {
      const arr = [
        this.fullName(result.firstName, result.lastName),
        this.fullName(result.firstNameEn, result.lastNameEn),
        this.fullName(result.lastName, result.firstName),
        this.fullName(result.lastNameEn, result.firstNameEn),
        result.phoneNumber,
      ];

      return _.some(arr, val =>
        _.includes(val.toLowerCase(), this.state.criteria.toLowerCase())
      );
    });
  };

  sortSuppliers(a, b) {
    const dateA = a.lastTransfer ? moment(a.lastTransfer) : null;
    const dateB = b.lastTransfer ? moment(b.lastTransfer) : null;
    const nameA = a.firstName + a.lastName;
    const nameB = b.firstName + b.lastName;

    // sort by date, with nulls last
    if ((dateA && !dateB) || (dateA && dateB && dateA.isAfter(dateB))) {
      return -1;
    }

    if ((!dateA && dateB) || (dateA && dateB && dateB.isAfter(dateA))) {
      return 1;
    }

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  }

  fullName = (firstName = '', lastName = '') => {
    return `${firstName} ${lastName}`.trim();
  };

  onSearchChange = () =>
    _.debounce((e, { value }) => this.setState({ criteria: value }), 500, {
      leading: true,
    });

  render() {
    const {
      t,
      match,
      loading,
      vendor = {
        image: '',
        name: '',
      },
      business: { lateOnPayments, isContractExpired },
    } = this.props;

    const suppliers = this.filterSuppliers();
    suppliers.sort(this.sortSuppliers);

    return (
      <div id={styles.suppliers}>
        <Loader loading={loading} />
        <Search
          input={{ icon: 'search', iconPosition: 'left' }}
          className={styles.search}
          onSearchChange={this.onSearchChange()}
          open={false}
          value={this.state.criteria}
        />
        <List>
          {suppliers.map(supplier => {
            const queryParams = querystring.stringify({
              v_id: match.params.v_id,
              s_id: supplier.id,
            });

            return (
              <List.Item
                as={Link}
                key={supplier.id}
                to={`/transfer-type?${queryParams}`}
                disabled={lateOnPayments || isContractExpired}
              >
                <Avatar src={supplier.image} className={styles.avatar_image} />
                <List.Content className={styles.content}>
                  <List.Header className={styles.header}>
                    {this.fullName(supplier.firstName, supplier.lastName)}
                  </List.Header>
                  <span className={styles.title}>{vendor.name}</span>
                  {supplier.lastTransfer && (
                    <List.Description className={styles.description}>
                      <span>
                        {t('suppliers.date')}:
                        {formatLocalDate(
                          supplier.lastTransfer,
                          DATE_TIME_FORMAT
                        )}
                      </span>
                    </List.Description>
                  )}
                </List.Content>
                <Image src={SupplierIcon} floated="right" />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    match: { params },
  } = props;

  const { v_id } = params;
  const vendor = vendorSelector(parseInt(v_id));

  return {
    suppliers: state.supplier.data,
    vendors: state.vendor.data,
    business: state.business.data,
    vendor: vendor(state),
    error: state.supplier.error,
    loading: state.supplier.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSuppliers: id => dispatch(getSuppliers(id)),
    fetchVendors: () => dispatch(getVendors()),
  };
};

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SuppliersScreen);
