import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Dimmer, Image } from 'semantic-ui-react';

import { DATE_TIME_FORMAT, NOTIFICATION_CHECK_INTERVAL } from '../../constants';
import { formatLocalDate } from '../../helpers/common';
import {
  markAsRead,
  unreadNotifications,
} from '../../redux/ducks/notifications';

import GCButton from '../GCButton';
import icons from '../../styles/svgs';
import styles from './index.module.scss';

const ModalBottomRenderer = ({ count }) => (
  <div className={styles.modal_bottom}>
    {count === 1 && (
      <div className={styles.rectangle}>
        <Image src={icons.LongModalBottomRectangle} centered />
      </div>
    )}

    {count >= 2 && (
      <>
        <div className={styles.rectangle}>
          <Image src={icons.LongModalBottomRectangle} centered />
        </div>
        <div className={styles.rectangle}>
          <Image src={icons.ShortModalBottomRectangle} centered />
        </div>
      </>
    )}
  </div>
);

class NextNotificationScreen extends React.PureComponent {
  state = {
    currentNotification: null,
  };

  componentDidMount() {
    this.props.getUnreadNotifications();

    this.notificationIntervalId = setInterval(
      this.props.getUnreadNotifications,
      NOTIFICATION_CHECK_INTERVAL
    );
  }

  componentWillUnmount() {
    this.notificationIntervalId && clearInterval(this.notificationIntervalId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { unreadNotifications } = this.props;
    const doesPropsChange =
      unreadNotifications.length !== prevProps.unreadNotifications.length;

    if (
      !this.state.currentNotification &&
      unreadNotifications.length &&
      doesPropsChange
    ) {
      this.setState({ currentNotification: unreadNotifications[0] });
    }
  }

  close = () => {
    const { currentNotification } = this.state;

    if (currentNotification) {
      this.props.markAsRead(currentNotification.id);
    }

    this.setState({ currentNotification: null });
  };

  render() {
    const { currentNotification } = this.state;
    const { t, unreadNotifications } = this.props;

    return (
      currentNotification && (
        <Dimmer inverted active id={styles.notification_container} page>
          <div className={styles.notification_block}>
            <div className={styles.notification_content}>
              <div>
                <Image
                  src={icons.Bell}
                  className={styles.notification_img}
                  centered
                />
              </div>
              <div className={styles.notification_header}>
                {t(`notifications.header`)}
              </div>
              <div className={styles.notification_date}>
                {formatLocalDate(
                  currentNotification.createdAt,
                  DATE_TIME_FORMAT
                )}
              </div>
              <div className={styles.notification_message}>
                {currentNotification.text}
              </div>
            </div>
            <div className={styles.notification_action}>
              <GCButton onClick={this.close} large>
                {t(`notifications.button`)}
              </GCButton>
            </div>
          </div>
          <ModalBottomRenderer count={unreadNotifications.length - 1} />
        </Dimmer>
      )
    );
  }
}

const mapStateToProps = state => ({
  unreadNotifications: state.notifications.unreadNotifications,
});

const mapDispatchToProps = dispatch => ({
  markAsRead: id => dispatch(markAsRead(id)),
  getUnreadNotifications: () => dispatch(unreadNotifications()),
});

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NextNotificationScreen);
