import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Input, FormField } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import Toast from '../Toast';
import { recovery } from '../../redux/ducks/login';
import GCButton from '../../components/GCButton';
import schema from '../../validation/schemas/forgotPasswordSchema';
import icons from '../../styles/svgs';
import CopyRight from '../../components/CopyRight';
import Validation from '../../components/Validation';
import Loader from '../Loader';
import AuthHeader from '../AuthHeader';

import styles from './index.module.scss';

class ForgotPassword extends React.PureComponent {
  onSubmit = ({ email }) => {
    this.props.recovery({ email });
  };

  componentDidUpdate(prevProps) {
    const { t, loading, error, history } = this.props;

    if (prevProps.loading && !loading && !error) {
      toast.success(<Toast text={t('forgot_password.message')} />);

      history.push('/login');
    }
  }

  render() {
    const { t, loading } = this.props;

    return (
      <div id={styles.forgot_password_screen}>
        <Loader loading={loading} />
        <AuthHeader />
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={schema}
          onSubmit={this.onSubmit}
          render={props => {
            const { values } = props;

            return (
              <Form className="ui form">
                <div className={styles.container}>
                  <img src={icons.Login} alt="global-credit-logo" />
                  <FormField>
                    <label htmlFor="email" className={styles.label}>
                      <span>{t('forgot_password.email')}</span>
                    </label>
                    <Validation name="email" showMessage={true}>
                      <Input
                        autoCapitalize="off"
                        value={values.email}
                        name="email"
                        className={styles.login_input}
                      />
                    </Validation>
                  </FormField>
                  <GCButton type="submit" large>
                    {t('forgot_password.button')}
                  </GCButton>
                </div>
              </Form>
            );
          }}
        />
        <CopyRight />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.login.loading,
  error: state.login.error,
});

const mapDispatchToProps = dispatch => ({
  recovery: data => {
    dispatch(recovery(data));
  },
});

export default compose(
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPassword);
