import React from 'react';
import { numberToDecimalString } from '../../helpers/numberHelpers';
import { Amd } from '../../styles/icons';

import styles from './index.module.scss';

const Currency = ({ hasSign, value, className, precision }) => (
  <div className={className}>
    {hasSign && <Amd />}
    <span className={styles.currency_price}>
      {numberToDecimalString(value, precision)}
    </span>
  </div>
);

export default Currency;
